export default (translate: any) => [
  {
    value: "blockchainDevelopers",
    label: translate("data.branches.blockchainDevelopers"),
  },
  {
    value: "cryptocurrencyAnalysts",
    label: translate("data.branches.cryptocurrencyAnalysts"),
  },
  { value: "cryptographers", label: translate("data.branches.cryptographers") },
  {
    value: "blockchainProjectManagers",
    label: translate("data.branches.blockchainProjectManagers"),
  },
  { value: "dataScientists", label: translate("data.branches.dataScientists") },
  {
    value: "legalProfessionals",
    label: translate("data.branches.legalProfessionals"),
  },
  {
    value: "blockchainConsultants",
    label: translate("data.branches.blockchainConsultants"),
  },
  { value: "uxUiDesigners", label: translate("data.branches.uxUiDesigners") },
  {
    value: "financialAnalysts",
    label: translate("data.branches.financialAnalysts"),
  },
  {
    value: "blockchainEducatorsTrainers",
    label: translate("data.branches.blockchainEducatorsTrainers"),
  },
  {
    value: "cybersecurityExperts",
    label: translate("data.branches.cybersecurityExperts"),
  },
  {
    value: "policyAndRegulatoryExperts",
    label: translate("data.branches.policyAndRegulatoryExperts"),
  },
  {
    value: "blockchainMarketingAndPrProfessionals",
    label: translate("data.branches.blockchainMarketingAndPrProfessionals"),
  },
  {
    value: "blockchainQualityAssuranceTesters",
    label: translate("data.branches.blockchainQualityAssuranceTesters"),
  },
  {
    value: "blockchainSalesProfessionals",
    label: translate("data.branches.blockchainSalesProfessionals"),
  },
  {
    value: "cryptoFundManagers",
    label: translate("data.branches.cryptoFundManagers"),
  },
  {
    value: "cryptoCommunityManagers",
    label: translate("data.branches.cryptoCommunityManagers"),
  },
  {
    value: "blockchainHrSpecialists",
    label: translate("data.branches.blockchainHrSpecialists"),
  },
  {
    value: "decentralizedFinanceDeFiSpecialists",
    label: translate("data.branches.decentralizedFinanceDeFiSpecialists"),
  },
  {
    value: "blockchainResearchScientists",
    label: translate("data.branches.blockchainResearchScientists"),
  },
  {
    value: "cryptoJournalists",
    label: translate("data.branches.cryptoJournalists"),
  },
  {
    value: "blockchainStartupFounders",
    label: translate("data.branches.blockchainStartupFounders"),
  },
  {
    value: "cryptocurrencyTraders",
    label: translate("data.branches.cryptocurrencyTraders"),
  },
  {
    value: "blockchainEconomists",
    label: translate("data.branches.blockchainEconomists"),
  },
  {
    value: "blockchainInfrastructureEngineers",
    label: translate("data.branches.blockchainInfrastructureEngineers"),
  },
  {
    value: "cryptoTaxSpecialists",
    label: translate("data.branches.cryptoTaxSpecialists"),
  },
  {
    value: "blockchainCustomerServiceRepresentatives",
    label: translate("data.branches.blockchainCustomerServiceRepresentatives"),
  },
  {
    value: "blockchainBusinessStrategists",
    label: translate("data.branches.blockchainBusinessStrategists"),
  },
  {
    value: "blockchainInvestmentAnalysts",
    label: translate("data.branches.blockchainInvestmentAnalysts"),
  },
  {
    value: "cryptocurrencyMiningEngineers",
    label: translate("data.branches.cryptocurrencyMiningEngineers"),
  },
  {
    value: "other",
    label: translate("data.branches.other"),
  },
];
