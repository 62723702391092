import { createI18n } from "vue-i18n";

import en from "./en.json";
import nl from "./nl.json";

export default createI18n({
  locale: localStorage.getItem("lang") || "nl",
  fallbackLocale: "en",
  messages: {
    en,
    nl,
  },
});
