<template>
  <div class="cookie-modal-overlay">
    <div class="cookie-modal-card" data-cy="cookie-modal-card">
      <div class="cookie-modal-card-header">
        <img
          src="@/assets/images/cookie/cookie-logo.png"
          alt="logo"
          class="pointer-events-none"
        />
      </div>

      <div class="cookie-modal-card-info">
        <div class="cookie-modal-card-info-header">
          {{ $t("pages.cookie.infoHeader") }}
        </div>
        {{ $t("pages.cookie.infoText") }}
        {{ $t("pages.cookie.infoText2") }}
        <div class="mt-1 font-semibold">
          {{ $t("pages.cookie.essentialCookiesInfo") }}
        </div>
      </div>

      <div class="cookie-modal-card-divider" />

      <div class="cookie-modal-card-cookie-preferences">
        <div class="cookie-modal-card-cookie-preferences-header">
          {{ $t("pages.cookie.cookiePreferencesHeader") }}
        </div>

        <div class="cookie-modal-card-cookie-preferences-list">
          <div class="cookie-modal-card-cookie-preferences-list-item">
            <div class="cookie-modal-card-cookie-preferences-list-item-row">
              <plus-svg
                v-if="!necessaryExpand"
                class="cookie-modal-card-cookie-preferences-list-item-expand crypto-talent-hover"
                @click="necessaryExpand = !necessaryExpand"
              />
              <minus-svg
                v-else
                class="cookie-modal-card-cookie-preferences-list-item-expand crypto-talent-hover"
                @click="necessaryExpand = !necessaryExpand"
              />
              <div
                class="cookie-modal-card-cookie-preferences-list-item-text crypto-talent-hover"
                @click="necessaryExpand = !necessaryExpand"
              >
                {{ $t("pages.cookie.necessaryCookies") }}
              </div>
            </div>
            <transition name="collapse">
              <div
                v-if="necessaryExpand"
                class="cookie-modal-card-cookie-preferences-list-item-expanded-text"
              >
                {{ $t("pages.cookie.necessaryCookiesText") }}
              </div>
            </transition>
          </div>

          <cookie-modal-preference-row
            :label="$t('pages.cookie.functionalCookies')"
            :text="$t('pages.cookie.functionalCookiesText')"
            :toggle="functional"
            @toggle-click="functional = !functional"
          />

          <cookie-modal-preference-row
            :label="$t('pages.cookie.targetingCookies')"
            :text="$t('pages.cookie.targetingCookiesText')"
            :toggle="targeting"
            @toggle-click="targeting = !targeting"
          />

          <cookie-modal-preference-row
            :label="$t('pages.cookie.socialMediaCookies')"
            :text="$t('pages.cookie.socialMediaCookiesText')"
            :toggle="socialMedia"
            @toggle-click="socialMedia = !socialMedia"
          />

          <cookie-modal-preference-row
            :label="$t('pages.cookie.adCookies')"
            :text="$t('pages.cookie.adCookiesText')"
            :toggle="ad"
            @toggle-click="ad = !ad"
          />

          <primary-button
            class="cookie-modal-card-cookie-preferences-button font-semibold"
            data-cy="cookie-modal-card-button"
            @button-click="confirm"
          >
            {{ $t("pages.cookie.confirm") }}
          </primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CookieModalPreferenceRow from "./CookieModalPreferenceRow.vue";

import PlusSvg from "@/assets/svg/form/plus.svg?component";
import MinusSvg from "@/assets/svg/form/minus.svg?component";

export default defineComponent({
  name: "CookieModal",
  components: {
    CookieModalPreferenceRow,
    PlusSvg,
    MinusSvg,
  },
  emits: ["dismiss"],
  data() {
    return {
      functional: true,
      targeting: true,
      socialMedia: true,
      ad: true,
      necessaryExpand: false,
    };
  },
  methods: {
    confirm() {
      localStorage.setItem(
        "cookiePreferences",
        JSON.stringify({
          functional: this.functional,
          targeting: this.targeting,
          socialMedia: this.socialMedia,
          ad: this.ad,
        }),
      );

      this.$emit("dismiss");
    },
  },
});
</script>

<style lang="scss" scoped>
.cookie-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 0, 51, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;

  @media print {
    display: none;
  }

  @media screen and (max-width: 430px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cookie-modal-card {
  position: relative;
  margin-top: 60px;
  width: 780px;
  height: fit-content;
  padding-bottom: 24px;
  background: rgba(181, 0, 253, 0.2);
  border: 1px solid #b500fd;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  border-radius: 20px;

  @media screen and (max-width: 430px) {
    width: 100%;
    margin-top: 14px;
  }

  @media screen and (min-width: 431px) and (max-width: 1366px) {
    margin-top: 30px;
  }

  &-header {
    position: relative;
    height: 64px;
    padding-left: 31px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(181, 0, 253, 0.2);
  }

  &-info {
    margin-top: 10px;
    margin-left: 31px;
    margin-right: 31px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;

    @media screen and (max-width: 430px) {
      margin-left: 24px;
      margin-right: 24px;
      font-size: 11px;
      line-height: 18px;
    }

    @media screen and (min-width: 431px) and (max-width: 1300px) {
      font-size: 12px;
      line-height: 18px;
    }

    &-header {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;

      @media screen and (max-width: 1300px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  &-cookie-preferences {
    margin-top: 20px;
    margin-left: 31px;
    margin-right: 31px;

    @media screen and (max-width: 430px) {
      margin-top: 12px;
      margin-left: 24px;
      margin-right: 24px;
    }

    &-header {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      text-transform: uppercase;

      @media screen and (max-width: 1300px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-list {
      @media screen and (max-width: 430px) {
        margin-top: 10px;
      }

      &-item {
        border-bottom: 1px solid rgba(181, 0, 253, 0.2);

        &-row {
          height: 52px;
          display: flex;
          align-items: center;
          gap: 14px;
        }

        &-text {
          flex: 1;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;

          @media screen and (max-width: 1300px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        &-expanded-text {
          height: 100%;
          max-height: 140px;
          padding-bottom: 24px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          @media screen and (max-width: 1300px) {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
    }

    &-button {
      width: 383px;
      height: 40px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 430px) {
        width: 100%;
      }
    }
  }

  &-divider {
    width: 100%;
    height: 1px;
    margin-top: 16px;
    background: rgba(181, 0, 253, 0.2);
  }
}
</style>

<style lang="scss">
.cookie-modal-card-cookie-preferences-list-item-row > .crypto-talent-highlight {
  @media screen and (max-width: 430px) {
    font-size: 11px;
    line-height: 18px;
  }
}
</style>
