import type {
  NavigationGuardNext,
  RouteLocation,
  RouteLocationNormalized,
} from "vue-router";

import {
  BusinessAuthGuard,
  BusinessBasicAuthGuard,
  BusinessAdminAuthGuard,
  BusinessBasicAuthGuardRedirect,
} from "./utils";
import { logout } from "@/http_client";
import AccountCard from "@/components/business/account/AccountCard.vue";

export default [
  {
    path: "/business-login",
    name: "business-login",
    component: () => import("../views/business/user/BusinessUserLoginView.vue"),
  },
  {
    path: "/business-login/:redirect",
    name: "business-login-redirect",
    component: () => import("../views/business/user/BusinessUserLoginView.vue"),
  },
  {
    path: "/business-verify",
    name: "business-verify",
    component: () =>
      import("../views/business/user/BusinessUserVerifyView.vue"),
  },
  {
    path: "/business-dashboard",
    name: "business-dashboard",
    beforeEnter: BusinessAuthGuard,
    component: () => import("@/views/business/BusinessDashboardView.vue"),
    children: [
      {
        path: "",
        name: "business-dashboard-home",
        component: () =>
          import("@/components/business/dashboard/DashboardCard.vue"),
      },
      {
        path: "cv-alert",
        name: "business-dashboard-cv-alert",
        component: () =>
          import("@/components/business/dashboard/CVAlertCard.vue"),
      },
      {
        path: "job-offers",
        name: "business-dashboard-job-offers",
        component: () =>
          import("@/components/business/dashboard/JobOffersCard.vue"),
      },
      {
        path: "applications",
        name: "business-dashboard-applications",
        component: () =>
          import("@/components/business/dashboard/ApplicationsCard.vue"),
      },
      {
        path: "applications/all",
        name: "business-dashboard-applications-all",
        component: () =>
          import("@/components/business/dashboard/ApplicationsAllCard.vue"),
      },
      {
        path: "applications/:id",
        name: "business-dashboard-applications-detail",
        component: () =>
          import("@/components/business/dashboard/ApplicationsDetailCard.vue"),
      },
      {
        path: "candidates",
        name: "business-dashboard-candidates",
        component: () =>
          import("@/components/business/dashboard/CandidatesCard.vue"),
      },
      {
        path: "candidates/sent-emails",
        name: "business-dashboard-candidates-sent-emails",
        component: () =>
          import(
            "@/components/business/dashboard/CandidatesSentEmailsCard.vue"
          ),
      },
      {
        path: "candidates/:id",
        name: "business-dashboard-candidates-detail",
        component: () =>
          import(
            "@/components/business/dashboard/CandidatesDetailPreviewCard.vue"
          ),
      },
      {
        path: "logo",
        name: "business-dashboard-logo",
        component: () => import("@/components/business/dashboard/LogoCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "account",
        name: "business-dashboard-account",
        component: AccountCard,
      },
      {
        path: "account/subscription",
        name: "business-dashboard-account-subscription",
        component: () =>
          import("@/components/business/account/SubscriptionCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "account/history-cv-credits",
        name: "business-dashboard-account-history-cv-credits",
        component: () =>
          import("@/components/business/account/HistoryCvCreditsCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "account/history-cv-credits-issued",
        name: "business-dashboard-account-history-cv-credits-issued",
        component: () =>
          import(
            "@/components/business/account/HistoryIssuedCvCreditsCard.vue"
          ),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "account/invoices",
        name: "business-dashboard-account-invoices",
        component: () =>
          import("@/components/business/account/InvoicesCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "account/invoices/:id",
        name: "business-dashboard-account-invoices-detail",
        component: () =>
          import("@/components/business/account/InvoicesDetailCard.vue"),
        beforeEnter: (
          to: RouteLocationNormalized,
          from: RouteLocationNormalized,
          next: NavigationGuardNext,
        ) =>
          BusinessBasicAuthGuardRedirect(
            to.name?.toString() as string,
            to.params.id as string,
            to,
            from,
            next,
          ),
      },
      {
        path: "account/billing-address",
        name: "business-dashboard-account-billing-address",
        component: () =>
          import("@/components/business/account/BillingAddressCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "manage-users",
        name: "business-dashboard-manage-users",
        component: () =>
          import("@/components/business/manage-users/ManageUsersCard.vue"),
        beforeEnter: BusinessAdminAuthGuard,
      },
      {
        path: "manage-users/add",
        name: "business-dashboard-manage-users-add",
        component: () =>
          import("@/components/business/manage-users/ManageUsersAddCard.vue"),
        beforeEnter: BusinessAdminAuthGuard,
      },
      {
        path: "manage-users/edit/:id",
        name: "business-dashboard-manage-users-edit",
        component: () =>
          import("@/components/business/manage-users/ManageUsersAddCard.vue"),
        beforeEnter: BusinessAdminAuthGuard,
      },
      {
        path: "settings",
        name: "business-dashboard-settings",
        component: () =>
          import("@/components/business/settings/SettingsCard.vue"),
      },
      {
        path: "change-password",
        name: "business-dashboard-change-password",
        component: () =>
          import("@/components/business/settings/ChangePasswordCard.vue"),
      },
      {
        path: "buy-credits",
        name: "business-dashboard-buy-credits",
        component: () =>
          import("@/components/business/buy-credits/BuyCreditsCard.vue"),
      },
      {
        path: "buy-credits/:id",
        name: "business-dashboard-buy-credits-detail",
        component: () =>
          import("@/components/business/buy-credits/BuyCreditsCard.vue"),
      },
      {
        path: "statistics",
        name: "business-dashboard-statistics",
        component: () =>
          import("@/components/business/statistics/StatisticsCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "appearance",
        name: "business-dashboard-appearance",
        component: () =>
          import("@/components/business/appearance/AppearanceCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "branch/:id",
        name: "business-dashboard-branch-detail",
        component: () => import("@/components/business/branch/BranchCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "branch/add",
        name: "business-dashboard-branch-add",
        component: () =>
          import("@/components/business/branch/BranchAddCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
      {
        path: "branch/:id/edit",
        name: "business-dashboard-branch-edit",
        component: () =>
          import("@/components/business/branch/BranchAddCard.vue"),
        beforeEnter: BusinessBasicAuthGuard,
      },
    ],
  },
  {
    path: "/business-dashboard/candidates/:id/download",
    name: "business-dashboard-candidates-detail-download",
    component: () => import("@/views/UserCVView.vue"),
  },
  {
    path: "/business-dashboard/candidates/:id/multiple-download",
    name: "business-dashboard-candidates-detail-multiple-download",
    component: () => import("@/views/UserCVMultipleView.vue"),
  },
  {
    path: "/business-thank-you",
    name: "business-thank-you",
    component: () => import("@/views/business/ThankYouvView.vue"),
  },
  {
    path: "/business-logout",
    name: "business-logout",
    beforeEnter: (
      _: unknown,
      from: RouteLocation,
      next: NavigationGuardNext,
    ) => {
      logout();
      if (from.name === "home" || from.name === "not-found") {
        window.location.reload();
        return;
      }
      next("/business-login");
      location.reload();
    },
  },
];
