<template>
  <div
    class="crypto-talent-app"
    :class="{
      'crypto-talent-app-print': isPrint,
    }"
  >
    <transition name="fade">
      <cookie-modal
        v-if="cookieModalToggle"
        @dismiss="cookieModalToggle = false"
      />
      <div v-else-if="cookieToggle" class="crypto-talent-app-cookie-modal-bar">
        <div class="crypto-talent-app-cookie-modal-bar-container">
          <div class="crypto-talent-app-cookie-modal-bar-text">
            <b>{{ $t("pages.cookie.cookieSettings") }}</b
            ><br />
            {{ $t("pages.cookie.infoText") }}
          </div>
          <div class="crypto-talent-app-cookie-modal-bar-actions">
            <a
              class="crypto-talent-hover underline"
              data-cy="cookie-alert-settings-button"
              @click="openCookieModal"
            >
              {{ $t("pages.cookie.cookieSettings") }}
            </a>
            <primary-button
              class="crypto-talent-app-cookie-modal-bar-actions-button font-semibold"
              data-cy="cookie-alert-accept-button"
              @button-click="acceptAllCookies"
            >
              {{ $t("pages.cookie.acceptAllCookies") }}
            </primary-button>
          </div>
        </div>
      </div>
    </transition>

    <RouterView />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRecaptchaProvider } from "vue-recaptcha";
import { RouterView } from "vue-router";

import CookieModal from "@/components/shared/modal/CookieModal.vue";
import { setHeader } from "./http_client";

export default defineComponent({
  components: {
    RouterView,
    CookieModal,
  },
  data() {
    return {
      cookieToggle: false,
      cookieModalToggle: false,
      paymentToggle: false,
      paymentTries: 0,
      isMobile: false,
      isHorizontalMobile: false,
      isTablet: false,
      isHorizontalTablet: false,
    };
  },
  computed: {
    isPrint(): boolean {
      return this.$route.path.includes("download");
    },
  },
  created() {
    useRecaptchaProvider();

    this.isMobile = window.innerWidth <= 430;
    this.isHorizontalMobile = window.innerWidth <= 768;
    this.isTablet = window.innerWidth <= 1024;
    this.isHorizontalTablet = window.innerWidth <= 1280;

    window.addEventListener("resize", this.onResize.bind(this));

    const query = new URLSearchParams(window.location.search);

    if (query.has("userToken")) {
      setHeader("UserAuthorization", `Bearer ${query.get("userToken")}`);
    }

    if (query.has("businessToken")) {
      setHeader(
        "BusinessAuthorization",
        `Bearer ${query.get("businessToken")}`,
      );
    }
  },
  mounted() {
    const cookie = localStorage.getItem("cookiePreferences");

    if (!cookie) {
      this.cookieToggle = true;
    }

    window.addEventListener(
      "open-cookie-modal",
      this.openCookieModal.bind(this),
    );
  },
  unmounted() {
    window.removeEventListener(
      "open-cookie-modal",
      this.openCookieModal.bind(this),
    );

    window.removeEventListener("resize", this.onResize.bind(this));
  },
  methods: {
    acceptAllCookies() {
      localStorage.setItem(
        "cookiePreferences",
        JSON.stringify({
          functional: true,
          targeting: true,
          socialMedia: true,
          ad: true,
        }),
      );

      this.cookieToggle = false;
    },
    openCookieModal() {
      this.cookieToggle = false;
      this.cookieModalToggle = true;
    },
    onResize() {
      this.isMobile = window.innerWidth <= 430;
      this.isHorizontalMobile = window.innerWidth <= 768;
      this.isTablet = window.innerWidth <= 1024;
      this.isHorizontalTablet = window.innerWidth <= 1280;
    },
  },
});
</script>

<style lang="scss" scoped>
.crypto-talent-app {
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: #1c0033;
  overflow: hidden;
  min-height: 100vh;

  @media print {
    min-height: auto;
  }
}

.crypto-talent-app-print {
  position: static;
  background-color: white;

  @media print {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.crypto-talent-app-cookie-modal-bar {
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 120px;
  display: flex;
  justify-content: center;
  background: rgba(93, 1, 139, 0.95);
  border-top: 1px solid #b500fd;
  z-index: 100;

  @media print {
    display: none;
  }

  @media screen and (max-width: 430px) {
    min-height: 160px;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 40px;

    @media screen and (max-width: 430px) {
      padding: 10px;
      flex-direction: column;
      gap: 4px;
    }
  }

  &-text {
    font-size: 14px;
    flex: 3;

    @media screen and (max-width: 430px) {
      flex: 1;
      font-size: 11px;
    }
  }

  &-actions {
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 2;

    @media screen and (max-width: 430px) {
      max-width: fit-content;
      height: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      flex: auto;
    }

    &-button {
      height: 32px !important;
      font-weight: 400 !important;

      @media screen and (max-width: 430px) {
        font-size: 14px !important;
      }
    }
  }
}

.payment-ellipse {
  position: absolute !important;
  top: 0;
}
</style>
