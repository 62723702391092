<template>
  <div
    class="form-checkbox-gradient-container"
    :data-cy="value ? `${name}-${value}` : name"
    @click="check"
  >
    <input
      :id="(Array.isArray(modelValue) ? value : name) as string"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="isChecked"
    />
    <span>
      <tick-square-svg v-if="isChecked || value" class="w-6 h-6" />
      <square-svg v-else class="w-6 h-6" />
    </span>
    <label
      :for="(Array.isArray(modelValue) ? value : name) as string"
      class="form-input-label form-checkbox-gradient-label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import squareSvg from "../../../assets/svg/form/checkbox/unchecked.svg?component";
import tickSquareSvg from "../../../assets/svg/form/checkbox/checked.svg?component";

export default defineComponent({
  components: {
    squareSvg,
    tickSquareSvg,
  },
  props: {
    modelValue: {
      type: [Array, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      checkValue: this.modelValue,
    };
  },
  computed: {
    isChecked() {
      return Array.isArray(this.modelValue)
        ? this.modelValue?.includes(this.value)
        : this.modelValue;
    },
  },
  methods: {
    check(e: MouseEvent) {
      if (e.target instanceof HTMLInputElement) {
        if (!Array.isArray(this.modelValue)) {
          this.checkValue = e.target.checked;
          return this.$emit("update:modelValue", e.target.checked);
        }
        if (e.target.checked) {
          const filteredModelValue = this.modelValue.filter(
            (v: any) => v !== (e.target as HTMLInputElement).value,
          );

          const newValue = [...filteredModelValue, e.target.value];

          this.checkValue = newValue;
          this.$emit("update:modelValue", newValue);
        } else {
          const newValue = this.modelValue.filter(
            (v: any) => v !== (e.target as HTMLInputElement).value,
          );

          this.checkValue = newValue;
          this.$emit("update:modelValue", newValue);
        }
        return;
      }
      e.preventDefault();

      if (!Array.isArray(this.modelValue)) {
        this.checkValue = !this.modelValue;
        return this.$emit("update:modelValue", !this.modelValue);
      }

      let newValue: any[];

      if (this.modelValue.includes(this.value)) {
        newValue = this.modelValue.filter((v: any) => v !== this.value);
      } else {
        newValue = [...this.modelValue, this.value];
      }

      this.checkValue = newValue;
      this.$emit("update:modelValue", newValue);
    },
  },
});
</script>

<style lang="scss" scoped>
// eslint-disable vue-scoped-css/no-unused-selector
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.form-checkbox-gradient {
  &-label {
    line-height: 1.5rem;
  }
  // eslint-disable-next-line vue-scoped-css/require-selector-used-inside
  &-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 32px;
    cursor: pointer;

    @media screen and (max-width: 430px) {
      padding-left: 32px;
    }

    & > label {
      cursor: pointer;
    }

    & > input {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    & > span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      & > svg {
        transform: scale(0.8);

        @media screen and (max-width: 768px) {
          transform: scale(0.65);
        }
      }
    }
  }
}
</style>
