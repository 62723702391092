<template>
  <div class="distance-select-wrapper">
    <div
      class="distance-select-container form-input crypto-talent-border"
      @click="clickSelect"
    >
      <select
        ref="select"
        v-model="value"
        :name="name"
        :placeholder="placeholder"
        class="distance-select"
        :data-cy="name"
        :aria-label="name"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <span class="distance-select-text"> {{ optionLabel }} </span>
      <span class="distance-select-prepend-icon">
        <routing-svg />
      </span>
      <span class="distance-select-append-icon">
        <arrow-down-svg />
      </span>
      <div v-if="selectToggle" class="distance-select-option-list">
        <div
          v-for="option in options"
          :key="option.value"
          class="distance-select-option-list-item"
          @click="selectItem($event, option.value)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import routingSvg from "@/assets/svg/Svg/All/linear/routing.svg?component";
import arrowDownSvg from "@/assets/svg/Svg/All/linear/arrow-down.svg?component";

import type { StringOption } from "global";

export default defineComponent({
  components: {
    routingSvg,
    arrowDownSvg,
  },
  props: {
    modelValue: { type: [String, Number], required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    options: {
      type: Array as () => StringOption[],
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.modelValue,
      selectToggle: false,
    };
  },
  computed: {
    optionLabel() {
      const option = this.options.find((option) => option.value === this.value);

      return option ? option.label : "";
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },
  mounted() {
    this.value = this.modelValue;

    window.addEventListener("click", this.onClickHandler);
  },
  unmounted() {
    window.removeEventListener("click", this.onClickHandler);
  },
  methods: {
    clickSelect(event: MouseEvent) {
      event.stopPropagation();

      this.selectToggle = !this.selectToggle;
    },
    selectItem(event: MouseEvent, value: string) {
      event.stopPropagation();

      this.value = value;
      this.selectToggle = false;
    },
    onClickHandler() {
      this.selectToggle = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.distance-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  max-height: 2.5rem;

  &-container {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    border: none;
    border-radius: 0 14px 14px 0;
    min-height: 3.25rem;
    cursor: pointer;

    @media screen and (max-width: 430px) {
      border-radius: 0 10px 10px 0;
    }

    &::after {
      border-radius: 0 14px 14px 0;
      background: linear-gradient(
        91.67deg,
        rgba(19, 227, 231, 0.9) -100%,
        rgba(167, 19, 251, 0.9) 100%
      );

      @media screen and (max-width: 430px) {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  &-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;

    min-width: 9.25rem;
  }

  &-option-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;

    background-color: rgba(35, 9, 56);
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

    &-item {
      padding: 0.5rem 1rem;

      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &-prepend-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;

    opacity: 0.4;
  }

  &-append-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;

    opacity: 0.8;
  }

  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.95);

    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
  }
}
</style>
