<template>
  <div class="relative">
    <button
      class="nav-bar-drawer-button fill-zinc-50 flex xl:gap-x-2 gap-x-1 relative items-center h-11 text-sm z-50"
      :data-cy="title"
      @mouseenter="handleDrawerMouseEnter"
      @click.prevent="handleDrawerClick"
    >
      <div
        class="nav-bar-drawer-button-avatar relative flex items-center justify-center"
      >
        <user-svg class="md:scale-100 sm:scale-75" />
      </div>
      <div class="nav-bar-drawer-button-text sm:block hidden">
        {{ title }}
      </div>
      <arrow-down-svg class="sm:block hidden" />
    </button>
    <transition name="fade">
      <div
        v-if="drawerToggle"
        name="drawer"
        class="nav-bar-drawer z-50 absolute top-14 sm:left-1/2 sm:-translate-x-1/2 backdrop-blur-md"
        @click.stop
        @mouseleave="drawerToggle = false"
      >
        <div class="nav-bar-drawer-triangle" />
        <slot />
      </div>
    </transition>
    <div
      v-if="drawerToggle"
      class="fixed top-0 left-0 w-full h-full z-40"
      @click="drawerToggle = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import arrowDownSvg from "@/assets/svg/Svg/All/linear/arrow-down.svg?component";
import UserSvg from "@/assets/svg/Svg/All/linear/user.svg?component";

export default defineComponent({
  components: {
    arrowDownSvg,
    UserSvg,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      drawerToggle: false,
    };
  },
  watch: {
    "$route.path"() {
      this.drawerToggle = false;
    },
  },
  methods: {
    handleDrawerMouseEnter() {
      if (!this.$root.isHorizontalMobile) {
        this.drawerToggle = true;
      }
    },
    handleDrawerClick() {
      this.drawerToggle = !this.drawerToggle;
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-bar-drawer {
  width: 201px;
  max-width: 201px;
  z-index: 30;
  background-color: rgba(62, 6, 99, 0.9);
  background: linear-gradient(
    180deg,
    rgba(35, 9, 56, 0.7) 13.22%,
    rgba(12, 0, 21, 0.7) 100%
  );
  border-radius: 10px;
  z-index: 100;

  @media screen and (max-width: 768px) {
    top: 44px;
    right: -8px;
    width: calc(50vw - 1px);
    border-radius: 0 0 0 10px;
  }

  &-triangle {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(181, 0, 253, 0.2);

    @media screen and (max-width: 768px) {
      transform: none;
      left: auto;
      right: 14px;
      border-bottom: 10px solid rgba(62, 6, 99, 0.8);
    }
  }

  &-button {
    &-avatar {
      height: 100%;
      width: 48px;
      border-radius: 50%;
      background-color: #b500fd;
      background: linear-gradient(120.74deg, #01fce5 -5.09%, #b500fd 102.65%);

      @media screen and (max-width: 640px) {
        background: none;
      }

      @media screen and (max-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }

    &-text {
      max-width: 200px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<style lang="scss">
.nav-bar-drawer {
  &-button > svg > path {
    stroke: #01fce5;
  }

  & > .nav-bar-drawer-link:first-of-type {
    border-radius: 10px 10px 0 0;
  }

  & > .nav-bar-drawer-link:last-of-type {
    border-top: 1px solid #b500fd;
    border-bottom: none;
    border-radius: 0 0 10px 10px;
  }
}
</style>
