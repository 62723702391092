<template>
  <div class="footer-view-wrapper">
    <div class="footer-view-container">
      <quick-search-row v-if="quickLinks" />
      <div
        class="footer-secondary-wrapper w-full min-h-[30rem] bg-[#b500fd4d] flex justify-center items-center overflow-hidden"
        :class="{
          'footer-secondary-wrapper-small': small,
        }"
      >
        <div
          class="w-full xl:max-w-[1240px] max-w-full flex flex-col items-center justify-between z-10 xl:px-0 lg:px-4 md:px-8 p-4"
        >
          <div
            class="w-full flex items-center md:justify-center justify-start md:gap-[90px]"
          >
            <div
              v-if="!small && !$root.isTablet"
              class="flex flex-col justify-between"
            >
              <img
                src="@/assets/images/footer/footer-logo.png"
                class="crypto-talent-hover block w-[253px] h-[57px]"
                alt="logo"
                @click="$router.push('/')"
              />
            </div>
            <div
              v-if="!small"
              class="footer-secondary-content grid lg:gap-8 gap-4 md:grid-cols-3 grid-cols-1 lg:my-32 md:my-10 my-2 md:flex-auto flex-1"
            >
              <div>
                <header class="text-lg font-semibold">
                  {{ $t("nav.footer.website.header") }}
                </header>
                <ul class="xs:my-4 my-[10px]">
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/about')"
                  >
                    {{ $t("nav.footer.jobSeekers.about") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/contact')"
                  >
                    {{ $t("nav.footer.jobSeekers.contact") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/terms')"
                  >
                    {{ $t("nav.footer.website.terms") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/privacy-policy')"
                  >
                    {{ $t("nav.footer.website.privacy") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="openCookie"
                  >
                    {{ $t("nav.footer.website.cookieSettings") }}
                  </li>
                </ul>
              </div>
              <div>
                <header class="text-lg font-semibold">
                  {{ $t("nav.footer.employers.header") }}
                </header>
                <ul class="xs:my-4 my-[10px]">
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/job-offer-create')"
                  >
                    {{ $t("nav.footer.employers.createJobOffer") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/business-faq')"
                  >
                    {{ $t("nav.footer.jobSeekers.faq") }}
                  </li>
                </ul>
                <primary-button
                  v-if="!isUser"
                  class="font-semibold md:h-9 h-10 md:w-48 w-full"
                  @click="$router.push('/business-login')"
                >
                  {{ $t("nav.footer.employers.header") }}
                </primary-button>
              </div>
              <div>
                <header class="text-lg font-semibold">
                  {{ $t("nav.footer.jobSeekers.header") }}
                </header>
                <ul class="xs:my-4 my-[10px]">
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/sign-up')"
                  >
                    {{ $t("nav.signup") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/faq')"
                  >
                    {{ $t("nav.footer.jobSeekers.faq") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/companies')"
                  >
                    {{ $t("nav.footer.jobSeekers.companies") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/career-guide')"
                  >
                    {{ $t("nav.footer.jobSeekers.careerGuide") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/blog')"
                  >
                    {{ $t("nav.footer.website.blog") }}
                  </li>
                  <li
                    class="xs:my-4 my-[10px] text-sm cursor-pointer transition-colors hover:text-[#13e3e7]"
                    @click="$router.push('/sign-up')"
                  >
                    {{ $t("nav.footer.jobSeekers.createResume") }}
                  </li>
                </ul>

                <div v-if="$root.isTablet" class="font-semibold mt-8 mb-1">
                  {{ $t("shared.language") }}
                </div>

                <language-selector v-if="$root.isTablet" />
              </div>
            </div>
          </div>
          <div
            v-if="!small"
            class="h-[1px] w-full crypto-talent-dashboard-card-main-divider mt-3"
          ></div>
          <div
            class="md:mt-0 mt-2 flex md:justify-between items-center md:flex-row flex-col w-full z-10 md:py-4 py-0 gap-5"
            :class="{
              'footer-secondary-content-bottom-small h-full md:mb-0': small,
            }"
          >
            <div class="flex items-center gap-x-2">
              <language-selector
                v-if="$root.isTablet && small"
                class="scale-[80%]"
              />
              <img
                v-if="$root.isMobile"
                src="@/assets/images/footer/footer-mobile-logo.png"
                alt="logo"
                class="h-9"
                @click="$router.push('/')"
              />
            </div>
            <div
              v-if="!$root.isMobile"
              class="md:text-lg text-sm font-semibold"
            >
              &#x24B8; 2023 Cryptotalent
            </div>
            <div
              class="md:text-lg text-sm font-semibold float-right flex md:gap-x-5 gap-x-3 items-center"
            >
              <instagram-svg
                class="crypto-talent-hover"
                @click="goTo('https://www.instagram.com/cryptotalent.nl')"
              />
              <twitter-svg
                class="crypto-talent-hover"
                @click="goTo('https://twitter.com/cryptotalenten')"
              />
              <linkedin-svg
                class="crypto-talent-hover scale-150"
                @click="
                  goTo('https://www.linkedin.com/company/cryptotalent-nl')
                "
              />
            </div>
            <div v-if="$root.isMobile" class="md:text-lg text-sm font-semibold">
              &#x24B8; 2023 Cryptotalent
            </div>
          </div>
        </div>
        <div
          v-if="!small && !$root.isMobile"
          class="footer-secondary-first-glow"
        ></div>
        <div
          v-if="!small && !$root.isMobile"
          class="footer-secondary-second-glow"
        ></div>
        <div
          v-if="small && !$root.isMobile"
          class="footer-secondary-small-glow"
        />
      </div>
      <div v-if="$root.isMobile" class="footer-view-mobile-glow"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { isLoggedInWithKey } from "@/http_client";

import QuickSearchRow from "./QuickSearch.vue";
import LanguageSelector from "../LanguageSelector.vue";

import instagramSvg from "@/assets/svg/socials/instagram.svg?component";
import twitterSvg from "@/assets/svg/socials/x.svg?component";
import linkedinSvg from "@/assets/svg/socials/linkedin.svg?component";

export default defineComponent({
  name: "FooterView",
  components: {
    QuickSearchRow,
    LanguageSelector,
    instagramSvg,
    twitterSvg,
    linkedinSvg,
  },
  props: {
    quickLinks: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUser(): boolean {
      return isLoggedInWithKey("UserAuthorization");
    },
  },
  methods: {
    goTo(url: string) {
      window.open(url, "_blank");
    },
    openCookie() {
      window.dispatchEvent(new CustomEvent("open-cookie-modal"));
      (document as any).scrollingElement.scrollTop = 0;
    },
  },
});
</script>

<style lang="scss" scoped>
.footer-view-wrapper {
  position: relative;
  width: 100vw;
  overflow-x: hidden;
  z-index: 99;
  background-color: #1c0033;
  background: linear-gradient(
    180deg,
    rgba(35, 9, 56, 0.7) 13.22%,
    rgba(12, 0, 21, 0.7) 100%
  );

  @media print {
    display: none;
  }
}

.footer-view-container {
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(35, 9, 56, 0.5) 13.22%,
    rgba(12, 0, 21, 0.5) 100%
  );
}

.footer-view-mobile-glow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: url("@/assets/images/footer/footer-mobile-glow.webp") no-repeat
    center center;
  z-index: 1;
}

// eslint-disable-next-line vue-scoped-css/require-selector-used-inside, vue-scoped-css/no-unused-selector
.footer-secondary {
  &-wrapper-small {
    min-height: 138px;
    background: linear-gradient(
      180deg,
      rgba(35, 9, 56, 0.7) 13.22%,
      rgba(12, 0, 21, 0.7) 100%
    );
  }

  &-first-glow {
    position: absolute;
    bottom: 0;
    left: 1rem;
    width: 34rem;
    height: 24.125rem;
    z-index: -1;
    background: #1e29fe;
    filter: blur(130px);
  }

  &-second-glow {
    position: absolute;
    bottom: 4rem;
    right: 2rem;
    width: 24rem;
    height: 24.125rem;
    z-index: -1;

    background: #1e29fe;
    filter: blur(130px);
    transform: matrix(0.31, 0.95, -0.94, 0.34, 0, 0);
  }

  &-small-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    background-image: url("@/assets/images/footer/footer-small-glow.webp");
    background-size: cover;
  }
}
</style>
