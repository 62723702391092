export default (translate: any) =>
  [
    ...[5, 10, 25, 50, 75, 100, 125, 150, 175, 200].map((distance) => ({
      value: distance.toString(),
      label: `${distance} km`,
    })),
    {
      value: "all",
      label: translate("shared.infiniteDistance"),
    },
  ] as StringOption[];
