<template>
  <div
    class="form-checkbox-terms-container"
    :class="{
      'form-checkbox-blue': blue,
    }"
    @click="check"
  >
    <input type="checkbox" name="terms" :value="value" :checked="isChecked" />
    <span data-cy="form-checkbox-terms">
      <tick-square-svg
        v-if="isChecked || value"
        class="w-6 h-6 crypto-talent-svg-medium"
      />
      <square-svg v-else class="w-6 h-6 crypto-talent-svg-medium" />
    </span>
    <label
      for="terms"
      class="form-input-label form-checkbox-terms-label cursor-pointer"
    >
      <span class="mr-1">
        {{ $t("shared.terms1") }}
      </span>
      <span class="underline crypto-talent-hover" @click.stop="openTerms">
        {{ $t("shared.terms2") }}
      </span>
      <span>{{ $t("shared.terms3") }}</span>
      <span class="underline crypto-talent-hover" @click.stop="openPrivacy">
        {{ $t("shared.terms4") }}
      </span>
      <span class="ml-1">
        {{ $t("shared.terms5") }}
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import squareSvg from "../../../assets/svg/form/checkbox/unchecked.svg?component";
import tickSquareSvg from "../../../assets/svg/form/checkbox/checked.svg?component";

export default defineComponent({
  components: {
    squareSvg,
    tickSquareSvg,
  },
  props: {
    modelValue: {
      type: [Array, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    blue: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      checkValue: this.modelValue,
    };
  },
  computed: {
    isChecked() {
      return Array.isArray(this.modelValue)
        ? this.modelValue?.includes(this.value)
        : this.modelValue;
    },
  },
  methods: {
    check(e: MouseEvent) {
      if (e.target instanceof HTMLInputElement) {
        if (!Array.isArray(this.modelValue)) {
          this.checkValue = e.target.checked;
          return this.$emit("update:modelValue", e.target.checked);
        }
        if (e.target.checked) {
          const filteredModelValue = this.modelValue.filter(
            (v: any) => v !== (e.target as HTMLInputElement).value,
          );

          const newValue = [...filteredModelValue, e.target.value];

          this.checkValue = newValue;
          this.$emit("update:modelValue", newValue);
        } else {
          const newValue = this.modelValue.filter(
            (v: any) => v !== (e.target as HTMLInputElement).value,
          );

          this.checkValue = newValue;
          this.$emit("update:modelValue", newValue);
        }
        return;
      }
      e.preventDefault();

      if (!Array.isArray(this.modelValue)) {
        this.checkValue = !this.modelValue;
        return this.$emit("update:modelValue", !this.modelValue);
      }

      let newValue: any[];

      if (this.modelValue.includes(this.value)) {
        newValue = this.modelValue.filter((v: any) => v !== this.value);
      } else {
        newValue = [...this.modelValue, this.value];
      }

      this.checkValue = newValue;
      this.$emit("update:modelValue", newValue);
    },
    openTerms() {
      const route = this.$router.resolve("/terms");
      window.open(route.href, "_blank");
    },
    openPrivacy() {
      const route = this.$router.resolve("/privacy-policy");
      window.open(route.href, "_blank");
    },
  },
});
</script>

<style lang="scss" scoped>
// eslint-disable vue-scoped-css/no-unused-selector
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.form-checkbox-terms {
  &-label {
    font-size: 14px;
    line-height: 1.5rem;

    @media screen and (max-width: 430px) {
      font-size: 12px;
    }
  }

  // eslint-disable-next-line vue-scoped-css/require-selector-used-inside
  &-container {
    position: relative;

    display: flex;
    align-items: center;
    padding-left: 2rem;

    cursor: pointer;

    & > input {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      opacity: 0;
      cursor: pointer;
    }

    & > span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
}
</style>
