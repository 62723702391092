<template>
  <div
    ref="select-element"
    :name="`form-select-${name}`"
    class="form-input-container relative"
    :class="{
      [cssClass]: true,
      'form-input-container-row': row,
      'form-input-mono-color': mono,
    }"
  >
    <label
      :for="name"
      class="form-input-label crypto-talent-font-bold"
      :class="{
        'form-input-label-row': row,
      }"
    >
      {{ label }}
      <span v-if="required && label" class="text-xs text-red-500">*</span>
      <slot name="label"></slot>
    </label>
    <div
      class="form-select-inner-container crypto-talent-border"
      :class="{
        'form-select-inner-container-row': row,
        'form-select-inner-container-opened': selectToggle,
        'form-input-inner-container-readonly': readonly || light,
        'opacity-80': disabled,
      }"
      @click="openOptions"
    >
      <select
        v-model="value"
        :name="name"
        :placeholder="placeholder"
        :multiple="multiple"
        autocomplete="off"
        class="form-input form-select appearance-none"
        :class="{
          [cssClassInput]: true,
          'text-gray-400': value === '',
          'form-input-readonly': readonly || light,
        }"
        :disabled="readonly || disabled"
        :data-cy="name"
        @change="validate"
      >
        <option
          v-if="!value || emptiable"
          :selected="!value"
          :disabled="!emptiable"
          value=""
        >
          {{ hint || `${$t("shared.empty")}...` }}
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          class="form-select-option"
        >
          {{ option.label }}
        </option>
      </select>
      <arrow-down-svg v-if="!oneOptionToggle" class="form-select-arrow-svg" />
    </div>
    <div
      v-if="selectToggle"
      :name="`select-option-list-${name}`"
      class="form-select-option-list-container crypto-talent-border"
      :class="{
        'form-select-option-list-row': row,
        'form-select-option-list-light': light,
      }"
    >
      <div class="form-select-option-list">
        <div
          v-if="emptiable"
          class="form-select-option-list-item"
          @click="selectItem($event, '')"
        >
          {{ hint || `${$t("shared.empty")}...` }}
        </div>
        <div
          v-for="option in options"
          :key="option.value"
          class="form-select-option-list-item"
          @click="selectItem($event, option.value)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
    <div
      v-if="validationError"
      class="form-input-validation-text"
      name="form-input-validation"
    >
      {{ validationErrorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import arrowDownSvg from "@/assets/svg/Svg/All/linear/arrow-down.svg?component";

import type { StringOption } from "global";

export default defineComponent({
  components: {
    arrowDownSvg,
  },
  props: {
    modelValue: { type: [String, Number], required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    cssClass: { type: String, required: false, default: "" },
    options: {
      type: Array as () => StringOption[],
      required: true,
    },
    multiple: { type: Boolean, required: false, default: false },
    emptiable: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    row: { type: Boolean, required: false, default: false },
    hint: { type: String, required: false, default: "" },
    cssClassInput: { type: String, required: false, default: "" },
    readonly: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    light: { type: Boolean, required: false, default: false },
    mono: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue", "value-change"],
  data() {
    return {
      value: this.modelValue,
      selectToggle: false,
      oneOptionToggle: false,
      validationError: false,
      validationErrorMessage: "",
    };
  },
  watch: {
    value() {
      this.$emit("value-change", this.value);
      this.$emit("update:modelValue", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
    options() {
      if (this.options.length === 1) {
        this.value = this.options[0].value;
        this.oneOptionToggle = true;
      } else {
        this.oneOptionToggle = false;
      }
    },
  },
  mounted() {
    this.value = this.modelValue;

    if (this.options.length === 1) {
      this.value = this.options[0].value;
      this.oneOptionToggle = true;
    } else {
      this.oneOptionToggle = false;
    }
  },
  methods: {
    openOptions(e: any) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.readonly && !this.disabled && !this.oneOptionToggle) {
        this.selectToggle = !this.selectToggle;

        if (this.selectToggle) {
          window.addEventListener("click", this.onClickOut.bind(this), true);
        }
      }
    },
    onClickOut(event: MouseEvent) {
      const element = this.$el.querySelector(
        `[name="select-option-list-${this.name}"]`,
      );

      // If mouse not on the element
      if (element && !element.contains(event.target as Node)) {
        this.selectToggle = false;
        window.removeEventListener("click", this.onClickOut.bind(this), true);
      }
    },
    selectItem(e: Event, value: string) {
      this.value = value;
      this.selectToggle = false;
      this.validate();
    },
    validate() {
      if (this.required && !this.value) {
        this.validationError = true;
        this.validationErrorMessage = this.$t("validation.required");
      } else {
        this.validationError = false;
        this.validationErrorMessage = "";
      }
    },
    onClickHandler() {
      this.selectToggle = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-select {
  pointer-events: none;

  &-inner-container {
    width: 100%;
    position: relative;

    &-opened {
      opacity: 0;
    }

    &-row {
      width: 65%;
    }
  }

  &-arrow-svg {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }

  &-option {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &-option-list {
    &-container {
      position: absolute;
      top: 0;
      left: 0;

      padding: 8px;
      width: 100%;
      height: fit-content;

      border-radius: 14px;

      background: linear-gradient(
        180deg,
        rgba(35, 9, 56, 0.95) 13.22%,
        rgba(12, 0, 21, 0.95) 100%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 1000;

      @media screen and (max-width: 768px) {
        top: 40px;
      }
    }

    position: relative;
    padding: 0 0.5rem;
    width: 100%;
    max-height: 420px;
    overflow-y: scroll;
    border-radius: 14px;

    &-item {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      padding: 0.2rem 0;
      font-size: 14px;
      line-height: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      align-items: flex-end;
      letter-spacing: 0.01em;
      color: #a5a5a5;

      &:hover {
        color: #13e3e7;
      }
    }

    &-light {
      background: #3e0663;

      &::after {
        background: none;
      }
    }

    &-row {
      @media screen and (max-width: 768px) {
        top: 40px;
      }

      top: 0;
      left: auto;
      right: 0;
      width: 65%;
    }
  }
}
</style>

<style lang="scss">
.form-input-small .form-select-option-list-container {
  @media screen and (max-width: 768px) {
    top: 0;
  }
}

.form-select-option-list {
  scrollbar-track-color: transparent;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: rgba(75, 49, 96, 1);
      border-radius: 14px;
    }
  }
}
</style>
