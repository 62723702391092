<template>
  <div class="form-input-container" :class="cssClass">
    <label
      v-if="!small"
      :for="name"
      class="form-input-label crypto-talent-font-bold"
    >
      {{ label }}<span v-if="required" class="text-xs text-red-500">*</span>
    </label>
    <div class="crypto-talent-border relative">
      <input
        v-model="value"
        :type="show ? 'text' : 'password'"
        :name="name"
        :placeholder="placeholder"
        class="form-input w-full"
        :class="cssClassInput"
        :data-cy="name"
      />
      <span v-if="!show" class="form-password-eye-svg" @click="show = !show">
        <eye-svg class="inline mb-1 eye-svg" />
      </span>
      <span v-else class="form-password-eye-svg" @click="show = !show">
        <eye-slash-svg class="inline mb-1 eye-svg" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import eyeSvg from "../../../assets/svg/Svg/All/linear/eye.svg?component";
import eyeSlashSvg from "../../../assets/svg/form/password/eye-slash.svg?component";

export default defineComponent({
  components: {
    eyeSvg,
    eyeSlashSvg,
  },
  props: {
    modelValue: { type: [String, Number], required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: true },
    placeholder: { type: String, required: true },
    cssClass: { type: String, required: false, default: "" },
    cssClassInput: { type: String, required: false, default: "" },
    required: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.modelValue,
      show: false,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-password-container {
  position: relative;
}

.form-password-eye-svg {
  position: absolute;
  top: calc(50% + 2px);
  transform: translateY(-50%);
  right: 20px;
}
</style>
