<template>
  <button
    :type="(type as any)"
    class="text-gray-600 bg-white border rounded border-gray-600 hover:bg-gray-600 hover:text-white transition cursor-pointer disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed"
    :class="
      (small
        ? 'px-3 py-1 text-sm font w-fit h-fit my-1 '
        : 'px-6 py-2 text-xl font-semibold w-full my-2 ') + cssClass
    "
    :disabled="disabled"
    :data-cy="dataCy || type"
    :title="title"
    @click="$emit('button-click')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: { type: String, required: false, default: "button" },
    cssClass: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    dataCy: { type: String, required: false, default: "" },
    small: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: "" },
  },
  emits: ["button-click"],
});
</script>
