<template>
  <span
    class="link-button flex items-center underline cursor-pointer"
    :data-cy="dataCy"
    @click="$emit('button-click')"
  >
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LinkButton",
  props: {
    dataCy: { type: String, required: false, default: "" },
  },
  emits: ["button-click"],
});
</script>
