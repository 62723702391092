export default (translate: any) => [
  {
    value: "permanent",
    label: translate("data.employmentForms.permanent"),
  },
  {
    value: "temporary",
    label: translate("data.employmentForms.temporary"),
  },
  {
    value: "freelance",
    label: translate("data.employmentForms.freelance"),
  },
  {
    value: "interim",
    label: translate("data.employmentForms.interim"),
  },
  {
    value: "sidejob",
    label: translate("data.employmentForms.sideJob"),
  },
  {
    value: "traineeship",
    label: translate("data.employmentForms.traineeship"),
  },
  {
    value: "internship",
    label: translate("data.employmentForms.internship"),
  },
];
