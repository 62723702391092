<template>
  <div @click="calendarToggle = !calendarToggle">
    <slot></slot>
  </div>
  <transition name="fade">
    <div v-if="calendarToggle" @mouseleave="calendarToggle = false">
      <div
        class="form-date-picker-triangle top-[60px] left-1/2 translate-x-[-50%]"
      ></div>
      <div
        class="form-date-popover absolute top-[72px] md:w-80 w-72 bg-white md:left-1/2 md:translate-x-[-50%] right-[-10px] rounded-xl md:p-3 p-4 text-[#0C0015] shadow-lg"
        :data-cy="dataCy"
      >
        <div class="flex justify-between">
          <div class="flex gap-x-2 items-end">
            <div class="text-sm">
              {{
                $t(`data.months.${moment(value).format("MMM").toLowerCase()}`)
              }}
            </div>
            <div class="text-2xl flex items-end leading-7 font-semibold">
              {{ moment(value).format("YYYY") }}
            </div>
          </div>
          <div class="flex gap-x-2 items-center pt-2">
            <div :data-cy="`${dataCy}-previous`" @click="previousMonth">
              <arrow-left-svg
                class="crypto-talent-black-svg crypto-talent-svg-small crypto-talent-hover"
              />
            </div>
            <div :data-cy="`${dataCy}-next`" @click="nextMonth">
              <arrow-right-svg
                class="crypto-talent-black-svg crypto-talent-svg-small crypto-talent-hover"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-7 gap-1 mt-3">
          <div
            v-for="calendarDay in calendarDays"
            :key="calendarDay.value"
            class="p-2 flex items-center justify-center rounded transition text-sm"
            :class="{
              'hover:bg-none cursor-not-allowed opacity-60':
                isBeforeMinDate(calendarDay.value) ||
                isAfterMaxDate(calendarDay.value),
              'hover:bg-gray-300 cursor-pointer':
                !isBeforeMinDate(calendarDay.value) &&
                !isAfterMaxDate(calendarDay.value),
              'bg-gray-200':
                moment(calendarDay.value).isSame(moment(value), 'month') &&
                !isBeforeMinDate(calendarDay.value) &&
                !isAfterMaxDate(calendarDay.value),
              'bg-[#B500FD] text-white font-semibold':
                moment(calendarDay.value).isSame(moment(value)) &&
                !isBeforeMinDate(calendarDay.value) &&
                !isAfterMaxDate(calendarDay.value),
            }"
            @click="selectCalendarDay(calendarDay.value)"
          >
            {{ calendarDay.label }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";

import ArrowLeftSvg from "@/assets/svg/Svg/All/linear/arrow-left-1.svg?component";
import ArrowRightSvg from "@/assets/svg/Svg/All/linear/arrow-right-1.svg?component";

export default defineComponent({
  name: "FormDatePicker",
  components: {
    ArrowLeftSvg,
    ArrowRightSvg,
  },
  props: {
    value: { type: String, required: true },
    minDate: { type: String, required: false, default: null },
    maxDate: { type: String, required: false, default: null },
    dataCy: { type: String, required: false, default: null },
  },
  emits: ["change"],
  data() {
    return {
      calendarToggle: false,
      moment,
    };
  },
  computed: {
    calendarDays(): { label: string; value: string }[] {
      let list = [];

      const modelValueMoment = moment(this.value).isValid()
        ? moment(this.value)
        : moment();

      const startDay = modelValueMoment
        .clone()
        .startOf("month")
        .startOf("week");
      const endDay = modelValueMoment.clone().endOf("month").endOf("week");

      while (startDay.isBefore(endDay)) {
        list.push({
          label: startDay.date().toString(),
          value: startDay.toISOString(),
        });
        startDay.add(1, "day");
      }

      return list;
    },
  },
  methods: {
    previousMonth() {
      const date = moment(this.value);

      if (!date.isValid()) {
        return;
      }

      date.subtract(1, "month");

      this.$emit("change", date.toISOString());
    },
    nextMonth() {
      const date = moment(this.value);

      if (!date.isValid()) {
        return;
      }

      date.add(1, "month");

      this.$emit("change", date.toISOString());
    },
    selectCalendarDay(value: string) {
      if (
        this.minDate &&
        moment(this.minDate).isValid() &&
        moment(value).isBefore(moment(this.minDate))
      ) {
        return;
      }

      this.$emit("change", value);
    },
    isBeforeMinDate(value: string): boolean {
      return Boolean(
        this.minDate &&
          moment(this.minDate).isValid() &&
          moment(value).isBefore(moment(this.minDate)),
      );
    },
    isAfterMaxDate(value: string): boolean {
      return Boolean(
        this.maxDate &&
          moment(this.maxDate).isValid() &&
          moment(value).isAfter(moment(this.maxDate)),
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.form-date-picker-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
}
</style>
