<template>
  <RouterLink
    :to="to"
    class="nav-bar-drawer-link flex items-center px-5 py-[6px] text-sm leading-5"
    :data-cy="to"
    :title="title"
  >
    <slot ref="slot"></slot>
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      required: false,
      default: "/",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-bar-drawer-link {
  min-height: 44px;
  width: 100%;
  border-bottom: 1px solid rgba(181, 0, 253, 0.5);

  transition: all 0.5s ease-in-out;

  &:hover {
    color: #d77afc;
    background: rgba(181, 0, 253, 0.2);
  }
}
</style>
