import { createHead } from "unhead";
import { createApp } from "vue";
import { VueRecaptchaPlugin } from "vue-recaptcha";

import App from "./App.vue";

import "./assets/main.scss";
import "./assets/FormAutocomplete.scss";

import router from "./router";
import i18n from "./translations/config";

import FooterView from "./components/shared/footer/FooterView.vue";

import LinkButton from "./components/shared/buttons/LinkButton.vue";
import PrimaryButton from "./components/shared/buttons/PrimaryButton.vue";
import SecondaryButton from "./components/shared/buttons/SecondaryButton.vue";

import AlertModal from "./components/shared/modal/AlertModal.vue";
import ConfirmModal from "./components/shared/modal/ConfirmModal.vue";

import FormAutocomplete from "./components/shared/form/FormAutocomplete.vue";
import FormAutocompleteCities from "./components/shared/form/FormAutocompleteCities.vue";
import FormCheckbox from "./components/shared/form/FormCheckbox.vue";
import FormCheckboxGradient from "./components/shared/form/FormCheckboxGradient.vue";
import FormCheckboxTerms from "./components/shared/form/FormCheckboxTerms.vue";
import FormCheckboxGroup from "./components/shared/form/FormCheckboxGroup.vue";
import FormDate from "./components/shared/form/FormDate.vue";
import FormInput from "./components/shared/form/FormInput.vue";
import FormPasswordLight from "./components/shared/form/FormPasswordLight.vue";
import FormPassword from "./components/shared/form/FormPassword.vue";
import FormSelect from "./components/shared/form/FormSelect.vue";
import FormRadio from "./components/shared/form/FormRadio.vue";
import FormButton from "./components/shared/form/FormButton.vue";
import FormDatePicker from "./components/shared/form/FormDatePicker.vue";
import FormUpload from "./components/shared/form/FormUpload.vue";

import HeartIcon from "./components/icons/HeartIcon.vue";
import UserIcon from "./components/icons/UserIcon.vue";
import MobileGoBack from "./components/shared/mobile/MobileGoBack.vue";

createHead();

const app = createApp(App);

app.use(VueRecaptchaPlugin, {
  v2SiteKey: import.meta.env.VITE_GOOGLE_CAPTCHA_KEY,
});

app.use(router);
app.use(i18n);

app
  .component("FooterView", FooterView)
  .component("LinkButton", LinkButton)
  .component("PrimaryButton", PrimaryButton)
  .component("SecondaryButton", SecondaryButton)
  .component("AlertModal", AlertModal)
  .component("ConfirmModal", ConfirmModal)
  .component("FormInput", FormInput)
  .component("FormPasswordLight", FormPasswordLight)
  .component("FormPassword", FormPassword)
  .component("FormSelect", FormSelect)
  .component("FormRadio", FormRadio)
  .component("FormButton", FormButton)
  .component("FormCheckbox", FormCheckbox)
  .component("FormCheckboxGradient", FormCheckboxGradient)
  .component("FormCheckboxTerms", FormCheckboxTerms)
  .component("FormCheckboxGroup", FormCheckboxGroup)
  .component("FormAutocomplete", FormAutocomplete)
  .component("FormAutocompleteCities", FormAutocompleteCities)
  .component("FormDate", FormDate)
  .component("FormDatePicker", FormDatePicker)
  .component("FormUpload", FormUpload)
  .component("HeartIcon", HeartIcon)
  .component("UserIcon", UserIcon)
  .component("MobileGoBack", MobileGoBack);

app.mount("#app");
