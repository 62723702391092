import { getBusinessUser, isLoggedInWithKey } from "@/http_client";
import type { NavigationGuardNext, RouteLocation } from "vue-router";

export default [
  {
    path: "/job-offers",
    name: "vacature-list",
    component: () => import("../views/vacature/VacatureListView.vue"),
  },
  {
    path: "/job-offers/:id",
    name: "vacature-list-detail",
    component: () => import("../views/vacature/VacatureListView.vue"),
  },
  {
    path: "/job-offers/advanced",
    name: "vacature-list-advanced",
    component: () => import("../views/vacature/VacatureSearchView.vue"),
  },
  {
    path: "/job-offer-create",
    name: "job-offer-create",
    component: () => import("../views/vacature/VacatureCreateView.vue"),
  },
  {
    path: "/job-offer-form/step-1",
    name: "job-offer-form-step-1",
    component: () => import("../views/vacature/VacaturePurchaseView.vue"),
  },
  {
    path: "/job-offer-form/step-2",
    name: "job-offer-form-step-2",
    component: () =>
      import("../views/vacature/VacaturePersonalDetailsView.vue"),
    beforeEnter: async (
      to: RouteLocation,
      __: RouteLocation,
      next: NavigationGuardNext,
    ) => {
      if (isLoggedInWithKey("BusinessAuthorization")) {
        const user = await getBusinessUser();

        if (!user) {
          localStorage.removeItem("businessToken");
        }
      }

      next();
    },
  },
  {
    path: "/job-offer-form/step-3",
    name: "job-offer-form-step-3",
    component: () => import("../views/vacature/VacatureFormView.vue"),
    beforeEnter: (
      to: RouteLocation,
      __: RouteLocation,
      next: NavigationGuardNext,
    ) => {
      if (
        localStorage.getItem("businessToken") ||
        to.fullPath.includes("payment-redirect") ||
        to.query.status === "payment-success"
      ) {
        return next();
      }
      next("/business-login");
    },
  },
];
