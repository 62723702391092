<template>
  <div
    class="form-input-container"
    :class="{
      'form-input-mono-color': mono,
      'pb-3': validationError,
    }"
  >
    <label
      v-if="label"
      :for="name"
      class="form-input-label crypto-talent-font-bold"
    >
      {{ label }} <span v-if="required" class="text-xs text-red-500">*</span>
    </label>
    <div
      class="crypto-talent-border relative h-fit"
      :class="{
        'crypto-talent-validation-border': validationError,
        'form-input-inner-container-readonly': readonly,
      }"
    >
      <input
        v-model="value"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        class="form-input w-full"
        :class="{
          'form-input-readonly': readonly,
        }"
        :disabled="readonly"
        :data-cy="name"
        autocomplete="off"
        @change="validate"
      />

      <div class="absolute right-3 top-1/2 translate-y-[-50%] w-fit h-fit z-50">
        <form-date-popover
          :value="modelValue"
          :min-date="minDate"
          :max-date="maxDate"
          @change="($event) => $emit('update:modelValue', $event)"
        >
          <calendar-svg class="crypto-talent-hover" />
        </form-date-popover>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="validationError"
        class="form-input-validation-text"
        :data-cy="`${name}-validation-text`"
      >
        {{ validationErrorMessage }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";

import FormDatePopover from "@/components/shared/form/FormDatePopover.vue";

import CalendarSvg from "@/assets/svg/Svg/All/linear/calendar.svg?component";

type ValidatorType = {
  name: String;
  validator: RegExp;
};

export default defineComponent({
  name: "FormDatePicker",
  components: {
    FormDatePopover,
    CalendarSvg,
  },
  props: {
    modelValue: { type: String, required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    type: { type: String, required: false, default: "text" },
    required: { type: Boolean, required: false, default: false },
    minDate: { type: String, required: false, default: null },
    maxDate: { type: String, required: false, default: null },
    validators: {
      type: Array,
      required: false,
      default: () => [] as ValidatorType[],
    },
    readonly: { type: Boolean, required: false, default: false },
    mono: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue", "value-change"],
  data() {
    return {
      value: "",
      calendarToggle: false,
      validationError: false,
      validationErrorMessage: "",
      moment,
    };
  },
  computed: {
    calendarDays(): { label: string; value: string }[] {
      let list = [];

      const modelValueMoment = moment(this.modelValue).isValid()
        ? moment(this.modelValue)
        : moment();

      const startDay = modelValueMoment
        .clone()
        .startOf("month")
        .startOf("week");
      const endDay = modelValueMoment.clone().endOf("month").endOf("week");

      while (startDay.isBefore(endDay)) {
        list.push({
          label: startDay.date().toString(),
          value: startDay.toISOString(),
        });
        startDay.add(1, "day");
      }

      return list;
    },
  },
  watch: {
    value() {
      if (this.value.length !== 10) {
        return;
      }

      const date = moment(this.value, "DD-MM-YYYY").hours(12);

      if (!date.isValid()) {
        return;
      }

      this.$emit("update:modelValue", date.toISOString());
    },
    modelValue() {
      if (!moment(this.modelValue).isValid()) {
        return;
      }

      this.parseModelValueToValue();
    },
  },
  mounted() {
    if (!moment(this.modelValue).isValid()) {
      return;
    }

    this.parseModelValueToValue();
  },
  methods: {
    validate() {
      this.$emit("value-change", this.value);

      if (!moment(this.value, "DD-MM-YYYY").isValid()) {
        this.validationError = true;
        this.validationErrorMessage = this.$t("info.invalid_date");
        return;
      }

      this.validationError = false;
    },
    parseModelValueToValue() {
      const date = moment(this.modelValue);

      this.value = date.format("DD-MM-YYYY");
    },
    previousMonth() {
      const date = moment(this.modelValue);

      if (!date.isValid()) {
        return;
      }

      date.subtract(1, "month");

      this.$emit("update:modelValue", date.toISOString());
    },
    nextMonth() {
      const date = moment(this.modelValue);

      if (!date.isValid()) {
        return;
      }

      date.add(1, "month");

      this.$emit("update:modelValue", date.toISOString());
    },
    selectCalendarDay(value: string) {
      this.$emit("update:modelValue", value);
    },
  },
});
</script>

<style lang="scss" scoped>
.form-date-picker-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ffffff;
}
</style>
