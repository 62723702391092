<template>
  <RouterLink
    :to="to"
    class="nav-bar-link"
    :class="expand ? 'inline-block w-full text-center py-1 font-normal' : ''"
    :data-cy="to"
    :title="title"
  >
    <slot ref="slot"></slot>
  </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      required: false,
      default: "/",
    },
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-bar-link {
  padding: 0.5rem 1rem;

  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  color: #fff;

  transition: all 0.5s ease-in-out;

  &:hover {
    color: #13e3e7;
  }
}
</style>
