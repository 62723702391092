<template>
  <div class="cookie-modal-preferences-list-item">
    <div class="cookie-modal-preferences-list-item-row">
      <plus-svg
        v-if="!expand"
        class="cookie-modal-preferences-list-item-expand crypto-talent-hover"
        @click="expand = !expand"
      />
      <minus-svg
        v-else
        class="cookie-modal-preferences-list-item-expand crypto-talent-hover"
        @click="expand = !expand"
      />
      <div
        class="cookie-modal-preferences-list-item-text crypto-talent-hover"
        @click="expand = !expand"
      >
        {{ label }}
      </div>
      <switch-on-svg
        v-if="toggle"
        class="crypto-talent-hover"
        @click="$emit('toggle-click', false)"
      />
      <switch-off-svg
        v-else
        class="crypto-talent-hover"
        @click="$emit('toggle-click', true)"
      />
    </div>
    <transition name="collapse">
      <div
        v-if="expand"
        class="cookie-modal-preferences-list-item-expanded-text"
      >
        {{ text }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PlusSvg from "@/assets/svg/form/plus.svg?component";
import MinusSvg from "@/assets/svg/form/minus.svg?component";
import SwitchOnSvg from "@/assets/svg/profile/switch-on.svg?component";
import SwitchOffSvg from "@/assets/svg/profile/switch-off.svg?component";

export default defineComponent({
  name: "CookieModalPreferenceRow",
  components: {
    PlusSvg,
    MinusSvg,
    SwitchOnSvg,
    SwitchOffSvg,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    toggle: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["toggle-click"],
  data() {
    return {
      expand: false,
    };
  },
});
</script>

<style lang="scss" scoped>
.cookie-modal-preferences-list-item {
  border-bottom: 1px solid rgba(181, 0, 253, 0.2);

  &-row {
    height: 52px;
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &-text {
    flex: 1;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    @media screen and (max-width: 430px) {
      font-size: 14px;
      line-height: 15px;
    }

    @media screen and (min-width: 431px) and (max-width: 1300px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-expanded-text {
    height: 100%;
    max-height: 140px;
    padding-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    @media screen and (max-width: 1300px) {
      font-size: 11px;
      line-height: 14px;
    }
  }
}
</style>
