<template>
  <button
    :type="(type as any)"
    :disabled="disabled"
    class="primary-button lg:py-2 lg:px-4 px-3 py-2 rounded-[10px] flex items-center justify-center lg:text-base text-sm"
    :class="cssClass"
    :data-cy="dataCy || type"
    @click="$emit('button-click')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: { type: String, required: false, default: "button" },
    cssClass: { type: String, required: false, default: "" },
    dataCy: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
  },
  emits: ["button-click"],
});
</script>

<style lang="scss" scoped>
.primary-button {
  background-color: #b500fd;
  background: linear-gradient(120.74deg, #01fce5 -5.09%, #b500fd 102.65%);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 430px) {
    min-width: 2rem;
  }

  @media screen and (min-width: 431px) and (max-width: 1024px) {
    min-width: 5rem;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: #ccc;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>
