<template>
  <div class="background-ellipse-container">
    <div class="background-ellipse"></div>
    <div class="background-ellipse-glow"></div>
    <!-- <div class="background-inner-ellipse"></div> -->
    <div class="background-inner-ellipse-glow"></div>
  </div>
</template>

<style lang="scss" scoped>
.background-ellipse-container {
  position: relative;
}

.background-ellipse {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  width: 100vw;
  aspect-ratio: 1441 / 1134;
  height: auto;

  background-position-y: 230px;
  background-image: url("@/assets/images/home/home-first-ellipse-min.webp");
  background-size: cover;

  opacity: 0.95;

  @media screen and (max-width: 768px) {
    max-width: 700px;
    overflow-x: hidden;
  }

  @media screen and (min-width: 1300px) {
    max-width: 1300px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1600px;
  }
}

.background-ellipse-glow {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  width: 100vw;
  height: auto;
  aspect-ratio: 1443 / 1339;

  background-image: url("@/assets/images/home/home-first-ellipse-glow.webp");
  background-size: cover;
  filter: blur(40px);
}

.background-inner-ellipse-glow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  background-image: url("@/assets/images/home/home-first-ellipse-small-glow.webp");
  background-size: cover;

  opacity: 0.5;

  width: 100vw;
  aspect-ratio: 1441 / 1227;
  height: auto;

  @media screen and (max-width: 1441px) {
    top: 60px;
    filter: blur(140px);
  }

  @media screen and (min-width: 1920px) {
    top: 80px;
    filter: blur(240px);
  }
}
</style>
