<template>
  <div v-if="$root.isMobile" class="crypto-talent-breadcrumb">
    <span class="crypto-talent-hover font-semibold" @click="goBack()"
      >{{ "<" }} {{ $t("shared.goBack") }}</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MobileGoBack",
  props: {
    to: {
      type: String,
      default: "/",
    },
  },
  methods: {
    goBack() {
      if (this.to) {
        this.$router.push(this.to);
        return;
      }

      this.$router.back();
    },
  },
});
</script>

<style lang="scss" scoped></style>
