<template>
  <div class="background-ellipse-container">
    <div class="background-ellipse"></div>
    <div class="background-ellipse-glow"></div>
  </div>
</template>

<style lang="scss" scoped>
.background-ellipse-container {
  position: relative;
}

.background-ellipse {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  aspect-ratio: 1601 / 1642;
  height: auto;
  background-image: url("@/assets/images/home/home-second-ellipse.webp");
  background-size: cover;

  @media screen and (max-width: 768px) {
    width: 700px;
    overflow-x: hidden;
  }

  @media screen and (min-width: 1300px) {
    width: 1300px;
  }

  @media screen and (min-width: 1600px) {
    width: 1600px;
  }
}

.background-ellipse-glow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  background-image: url("@/assets/images/home/home-second-ellipse-glow.webp");
  background-size: cover;

  width: 100vw;
  aspect-ratio: 1601 / 1738;
  height: auto;

  @media screen and (max-width: 768px) {
    top: -80px;
    width: 600px;
    overflow-x: hidden;
    filter: blur(140px);
  }

  @media screen and (min-width: 1601px) {
    top: -40px;
    filter: blur(80px);
  }
}
</style>
