import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { getBusinessUser, getHeader, isLoggedInWithKey } from "../http_client";
import type { BusinessUserFullyPopulated } from "@/models/business.user";

export const UserAuthGuard =
  (route: string) =>
  (
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const token = getHeader("UserAuthorization");
    if (token && typeof token === "string" && !token.includes("null")) {
      next();
    } else {
      next(route);
    }
  };

export async function BusinessAuthGuard(
  to: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (to.name === "business-dashboard-account-invoices-detail") {
    return next();
  }

  if (to.query.status === "payment-success") {
    return next();
  }

  const businessUser = (await getBusinessUser()) as BusinessUserFullyPopulated;

  if (!businessUser) {
    return logoutBusiness();
  }

  next();
}

export async function BusinessBasicAuthGuard(
  to: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (to.query.status === "payment-success") {
    return next();
  }

  const businessUser = (await getBusinessUser()) as BusinessUserFullyPopulated;

  if (!businessUser) {
    return logoutBusiness();
  }

  if (businessUser?.admin || businessUser?.marketing) {
    return next();
  }

  location.href = "/business-dashboard";
}

export async function BusinessBasicAuthGuardRedirect(
  redirect: string,
  param: string,
  to: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (to.query.status === "payment-success") {
    return next();
  }

  const businessUser = (await getBusinessUser(
    false,
    false,
  )) as BusinessUserFullyPopulated;

  if (!businessUser) {
    return logoutBusiness(redirect, param);
  }

  if (businessUser?.admin || businessUser?.marketing) {
    return next();
  }

  location.href = "/business-dashboard";
}

export async function BusinessAdminAuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  if (to.query.status === "payment-success") {
    return next();
  }

  const businessUser = (await getBusinessUser()) as BusinessUserFullyPopulated;

  if (!businessUser) {
    return logoutBusiness();
  }

  if (businessUser?.admin) {
    return next();
  }

  location.href = "/business-dashboard";
}

function logoutBusiness(redirect: string = "", param: string = "") {
  localStorage.removeItem("businessToken");

  if (redirect) {
    if (param) {
      return (location.href = `/business-login?redirect=${redirect}&param=${param}`);
    }

    return (location.href = `/business-login?redirect=${redirect}`);
  }
  location.href = "/business-login";
}
