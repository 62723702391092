<template>
  <div
    class="form-input-container"
    :class="{
      [cssClass]: true,
      'form-input-container-row': row,
      'form-autocomplete-container': !row,
      'form-input-mono-color': mono,
    }"
  >
    <label
      v-if="!small"
      :for="name"
      class="form-input-label crypto-talent-font-bold"
      :class="{
        [cssClassLabel]: true,
        'form-input-label-row': row,
      }"
    >
      {{ label }} <span v-if="required" class="text-xs text-red-500">*</span>
    </label>
    <div
      class="form-autocomplete-inner-container crypto-talent-border"
      :class="{
        [cssClassContainer]: true,
        'form-input-row': row,
      }"
    >
      <input
        v-model="value"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        class="form-input"
        :class="{
          [cssClassInput]: true,
        }"
        :data-cy="name"
        autocomplete="off"
        @focus="focused = true"
        @blur="focusOut"
        @change="$emit('value-change', value)"
        @keyup.enter="navigateConfirm"
        @keyup.up="navigateUp"
        @keyup.down="navigateDown"
      />
      <span @click="value = ''">
        <close-square-svg
          :class="{
            'crypto-talent-svg-medium': $root.isMobile,
          }"
        />
      </span>
      <ul
        v-if="showOptions && focused && optionsList.length > 0"
        class="form-autocomplete-list"
        :class="{
          'form-autocomplete-list-row': row,
        }"
      >
        <li
          v-for="(item, index) in optionsList"
          :key="item.value"
          :data-cy="`${name}-option-${item.label.toLowerCase()}`"
          class="form-autocomplete-list-item"
          :class="{
            'form-autocomplete-list-item__hover': selectedIndex === index,
          }"
          @click="value = item[selectedValue]"
        >
          {{ item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import closeSquareSvg from "@/assets/svg/Svg/All/linear/close-square.svg?component";

export default defineComponent({
  components: {
    closeSquareSvg,
  },
  props: {
    modelValue: { type: String, required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    placeholder: { type: String, required: true },
    type: { type: String, required: false, default: "text" },
    cssClass: { type: String, required: false, default: "" },
    cssClassInput: { type: String, required: false, default: "" },
    cssClassLabel: { type: String, required: false, default: "" },
    cssClassContainer: { type: String, required: false, default: "" },
    options: { type: Array, required: false, default: () => [] },
    small: { type: Boolean, required: false, default: false },
    selectedValue: { type: String, required: false, default: "label" },
    row: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    mono: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue", "value-change"],
  data() {
    return {
      value: this.modelValue,
      selectedIndex: -1,
      focused: false,
      timer: null as any,
      optionsList: [] as any[],
    };
  },
  computed: {
    showOptions() {
      return this.options.length > 0 && this.value.length > 0;
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);

      if (this.value.length === 0) {
        this.optionsList = [];
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.optionsList = this.options
          .filter((option: any) =>
            option.label.toLowerCase().includes(this.value.toLowerCase()),
          )
          .sort((a: any) => {
            if (a.label === this.value) {
              return -1;
            }
            return 1;
          })
          .slice(0, 6);
      }, 200);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    focusOut() {
      setTimeout(() => {
        this.focused = false;
      }, 200);
    },
    navigateConfirm(event: any) {
      event.preventDefault();

      if (this.selectedIndex !== -1) {
        this.value = this.optionsList[this.selectedIndex].label;
        this.focused = false;
      }
    },
    navigateUp(event: any) {
      event.preventDefault();

      if (this.selectedIndex !== 0) {
        this.selectedIndex--;
      }
    },
    navigateDown(event: any) {
      event.preventDefault();

      if (this.selectedIndex < this.optionsList.length - 1) {
        this.selectedIndex++;
      }
    },
  },
});
</script>
