import type { RouteRecordRaw } from "vue-router";
import { UserAuthGuard } from "./utils";

export default [
  {
    path: "/user-profile",
    name: "user-profile",
    component: () => import("../views/user/UserProfileView.vue"),
  },
  {
    path: "/user-mailing",
    name: "user-mailing",
    component: () => import("../views/user/UserMailingView.vue"),
  },
  {
    path: "/user-favorites",
    name: "user-favorites",
    component: () => import("../views/user/UserFavoritesView.vue"),
  },
  {
    path: "/user-favorites/:id",
    name: "user-favorites-detail",
    component: () => import("../views/user/UserFavoritesView.vue"),
  },
  {
    path: "/user-applications",
    name: "user-applications",
    component: () => import("../views/user/UserApplicationsView.vue"),
  },
  {
    path: "/user-settings",
    name: "user-settings",
    component: () => import("../views/user/UserSettingsView.vue"),
  },
  {
    path: "/user-change-password",
    name: "user-change-password",
    component: () => import("../views/user/UserChangePasswordView.vue"),
  },
  {
    path: "/user-download",
    name: "user-download",
    component: () => import("../views/UserCVView.vue"),
  },
].map((route: RouteRecordRaw) => ({
  ...route,
  beforeEnter: UserAuthGuard("/login"),
}));
