<template>
  <div
    class="form-input-container"
    :class="{
      [cssClass]: true,
      'form-input-container-row': row,
      'form-input-mono-color': mono,
      'pb-[18px]': validationError && !hideValidation,
    }"
  >
    <label
      v-if="!small"
      :for="name"
      class="form-input-label font-semibold"
      :class="{
        'form-input-label-row': row,
      }"
    >
      {{ label }} <span v-if="required" class="text-xs text-red-500">*</span>
      <slot name="label"></slot>
    </label>
    <div
      class="crypto-talent-border relative h-fit form-input-inner-container"
      :class="{
        'form-input-row': row,
        'form-input-inner-container-readonly': readonly || light,
      }"
    >
      <input
        v-if="rows === 1"
        :id="name"
        v-model="value"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        class="form-input w-full"
        :class="{
          [cssClassInput]: true,
          'form-input-readonly': readonly || light,
        }"
        :disabled="readonly || disabled"
        :data-cy="name"
        :autocomplete="type === 'password' ? 'current-password' : 'off'"
        @change="validate"
      />
      <div v-else class="form-textarea-wrapper p-2 overflow-hidden">
        <textarea
          v-model="value"
          :name="name"
          :placeholder="placeholder"
          class="form-input form-textarea max-h-[200px]"
          :class="{
            [cssClassInput]: true,
            'form-input-readonly': readonly || light,
          }"
          :rows="rows"
          :data-cy="name"
          :disabled="readonly"
          autocomplete="off"
          @input="textAreaOnChange"
          @change="validate"
        />
      </div>
      <transition name="fade" mode="out-in">
        <div
          v-if="validationError && !hideValidation"
          class="form-input-validation-text md:pt-1 pt-4"
          :class="{
            'form-input-validation-text-row': row,
          }"
          name="form-input-validation"
          :data-cy="`${name}-validation-text`"
        >
          {{ validationErrorMessage }}
        </div>
      </transition>
      <slot name="validation"></slot>
    </div>

    <div
      v-if="maxLength"
      class="absolute right-5 bottom-1 text-[10px] bg-[#230938fd] px-1 py-[2px] rounded-sm"
    >
      {{ (value as string).length || 0 }} / {{ maxLength }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import validators from "../../../data/validators";

type ValidatorType = {
  name: String;
  validator: RegExp;
};

export default defineComponent({
  props: {
    modelValue: { type: [String, Number], required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    type: { type: String, required: false, default: "text" },
    cssClass: { type: String, required: false, default: "" },
    cssClassInput: { type: String, required: false, default: "" },
    rows: { type: Number, required: false, default: 1 },
    required: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    row: { type: Boolean, required: false, default: false },
    validators: {
      type: Array,
      required: false,
      default: () => [] as ValidatorType[],
    },
    hideValidation: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    light: { type: Boolean, required: false, default: false },
    mono: { type: Boolean, required: false, default: false },
    maxLength: { type: Number, required: false, default: 0 },
  },
  emits: ["update:modelValue", "value-change"],
  data() {
    return {
      validationError: false,
      validationErrorMessage: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: string | number) {
        if (
          this.maxLength &&
          typeof value === "string" &&
          value.length > this.maxLength
        ) {
          const shortenedValue = value.slice(0, this.maxLength);

          this.value = shortenedValue;
          this.$emit("update:modelValue", shortenedValue);

          this.$forceUpdate();
          return;
        }

        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
    required() {
      this.validate();
    },
  },
  mounted() {
    if (this.rows > 1) {
      this.textAreaOnChange({ target: this.$el.querySelector("textarea") });
    }
  },
  methods: {
    validate() {
      this.$emit("value-change", this.value);

      if (this.value === "" && !this.required) {
        this.validationError = false;
        this.validationErrorMessage = "";
        return;
      }

      if (!Array.isArray(this.validators) || !this.validators.length) {
        if (!this.required) {
          return;
        }
      }
      const validationResult = [
        ...(this.required
          ? [{ name: "required", validator: validators.required }]
          : []),
        ...this.validators,
      ].find(
        (validator: any) => !validator?.validator?.test(this.value as string),
      ) as ValidatorType;

      if (validationResult) {
        if (this.$te(`validation.${validationResult.name}`)) {
          this.validationErrorMessage = this.$t(
            `validation.${validationResult.name}`,
          ) as string;
        } else {
          this.validationErrorMessage = this.$t(`validation.generic`, {
            name: this.label.toLowerCase(),
          }) as string;
        }
      } else {
        this.validationErrorMessage = "";
      }
      this.validationError = !!validationResult;
      return validationResult;
    },
    textAreaOnChange($event: any) {
      const element = $event.target as HTMLInputElement;
      element.style.height = "1px";
      element.style.height = 25 + element.scrollHeight + "px";
    },
  },
});
</script>

<style lang="scss" scoped>
.form-input-container-row {
  display: flex;
}

.form-input-label-row {
  width: 35%;

  font-size: 1.1rem;
}

.form-input-row {
  width: 65%;
}

.form-textarea {
  resize: none;
  min-height: 200px;
  height: 100%;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: scroll;
  background: none;
  padding: 8px;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &-wrapper {
    background-color: #230938f2;
    background: linear-gradient(
      180deg,
      rgba(35, 9, 56, 0.7) 13.22%,
      rgba(12, 0, 21, 0.7) 100%
    );
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
  }
}
</style>
