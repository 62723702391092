export type Validator = {
  name: string;
  validator: RegExp | null;
};

export default {
  email:
    // eslint-disable-next-line no-control-regex
    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  phone:
    /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/,
  postalCode: /^[0-9]{4}[ ]{0,1}[A-Za-z]{2}$/,
  address: /^[\w- ]+[\d/+]+$/,
  required: /\S+/,
  // Regex for a number between 1 and 40
  workingHours: /^([1-9]|[1234567][0-9]|80)$/,
  salaryHour: /^[1-9][0-9]|\d{4,15}$/,
  salaryWeek: /^[1-9][0-9][0-9]|\d{4,15}$/,
  salary: /^[5-9][0-9][0-9]|\d{4,15}$/,
  vacatureCredits: /^[0-2][0-9]$/,
  url: /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
  number: /^[1-9][0-9]*$/,
  passwordOfLength8: /^\S{8,}$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
  date: /^[0-3]{1}\d{1}-[0-1]{1}\d{1}-\d{4}$/,
  day: /^[0-3]{0,1}\d{1}$/,
  month: /^[0-1]{0,1}\d{1}$/,
  year: /^(19|20)\d{2}$/,
};
