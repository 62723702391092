<template>
  <div class="form-input-password-container crypto-talent-border">
    <input
      v-model="value"
      :name="name"
      :placeholder="placeholder"
      :data-cy="name"
      class="form-input-password-input"
      :type="hiddenToggle ? 'password' : 'text'"
      autocomplete="off"
    />
    <eye-svg
      v-if="hiddenToggle"
      class="form-input-password-svg"
      @click="hiddenToggle = !hiddenToggle"
    />
    <eye-slag-svg
      v-else
      class="form-input-password-svg"
      @click="hiddenToggle = !hiddenToggle"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import EyeSvg from "@/assets/svg/Svg/All/linear/eye.svg?component";
import EyeSlagSvg from "@/assets/svg/Svg/All/linear/eye-slash.svg?component";

export default defineComponent({
  name: "FormInputPassword",
  components: {
    EyeSvg,
    EyeSlagSvg,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: "",
      hiddenToggle: true,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },
});
</script>

<style lang="scss" scoped>
.form-input-password-container {
  position: relative;
  border-radius: 14px;
}

.form-input-password-input {
  position: relative;
  height: calc(100% - 2px);
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 14px;
  color: rgba(255, 255, 255, 0.8);
  background: none;

  &:focus {
    outline: none;
  }
}

.form-input-password-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
</style>
