<template>
  <button
    :type="(type as any)"
    :disabled="disabled"
    class="secondary-button font-semibold p-1 rounded-[10px] bg-[#200836] text-white lg:text-base text-sm crypto-talent-border relative after:rounded-[10px] transition hover:opacity-70 hover:bg-[#1f0733]"
    :class="{
      [cssClass]: true,
    }"
    :data-cy="type"
    @click="$emit('button-click')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    type: { type: String, required: false, default: "button" },
    cssClass: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    mono: { type: Boolean, required: false, default: false },
  },
  emits: ["button-click"],
});
</script>

<style lang="scss" scoped>
.secondary-button {
  &:disabled {
    color: #aaaaaa;
    opacity: 0.8;
    background: linear-gradient(
      180deg,
      rgba(35, 9, 56, 1) 13.22%,
      rgba(12, 0, 21, 1) 100%
    );
    cursor: not-allowed;
  }
}
</style>
