import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocation,
  type RouteLocationNormalized,
} from "vue-router";

import HomeView from "../views/HomeView.vue";

import businessRoutes from "./business";
import vacatureRoutes from "./vacature";
import userRoutes from "./user";

import { logout, isLoggedInWithKey } from "../http_client";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (
      (to.path.includes("job-offers") && from.path.includes("job-offers")) ||
      (to.path.includes("candidates") && from.path.includes("candidates"))
    ) {
      return;
    }
    if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
    };
  },
  routes: [
    {
      path: "/payment-redirect/:pathMatch(.*)*",
      name: "payment-redirect",
      redirect: (to: RouteLocationNormalized) => {
        const { fullPath } = to;

        const newPath = fullPath.replace("/payment-redirect", "");

        if (newPath.includes("?")) {
          return `${newPath}&status=payment-success`;
        }

        return `${newPath}?status=payment-success`;
      },
    },
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    ...vacatureRoutes,
    ...businessRoutes,
    ...userRoutes,
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("../views/ContactView.vue"),
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("../views/FaqView.vue"),
    },
    {
      path: "/business-faq",
      name: "business-faq",
      component: () => import("../views/business/BusinessFaqView.vue"),
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import("../views/TermsView.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("../views/PrivacyPolicyView.vue"),
    },
    {
      path: "/career-guide",
      name: "career-guide",
      component: () => import("../views/career-guide/CareerGuideView.vue"),
    },
    {
      path: "/career-guide/:id",
      name: "career-guide-detail",
      component: () =>
        import("../views/career-guide/CareerGuideDetailView.vue"),
    },
    {
      path: "/blog",
      name: "blog",
      component: () => import("../views/blog/BlogView.vue"),
    },
    {
      path: "/blog/:id",
      name: "blog-detail",
      component: () => import("../views/blog/BlogDetailView.vue"),
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("../views/companies/CompaniesView.vue"),
    },
    {
      path: "/companies/:id",
      name: "companies-detail",
      component: () => import("../views/companies/CompaniesDetailView.vue"),
    },
    {
      path: "/companies/search",
      name: "companies-search",
      component: () => import("../views/companies/CompaniesSearchView.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/UserLoginView.vue"),
    },
    {
      path: "/login/:redirect",
      name: "login-redirect",
      component: () => import("../views/UserLoginView.vue"),
    },
    {
      path: "/sign-up",
      name: "sign-up",
      beforeEnter: (_: RouteLocation, __: RouteLocation, next) => {
        if (isLoggedInWithKey("UserAuthorization")) {
          return next("/user-profile");
        }

        if (isLoggedInWithKey("BusinessAuthorization")) {
          return next("/business-dashboard");
        }

        next();
      },
      component: () => import("../views/SignUpView.vue"),
    },
    {
      path: "/sign-up/:redirect",
      name: "sign-up-redirect",
      component: () => import("../views/SignUpView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (
        to: RouteLocation,
        from: RouteLocation,
        next: NavigationGuardNext,
      ) => {
        logout();
        if (from.name === "home" || from.name === "not-found") {
          window.location.reload();
          return;
        }
        next(`/?logout=true`);
      },
    } as any,
    {
      path: "/user-forgot-password",
      name: "user-forgot-password",
      component: () => import("../views/user/UserForgotPasswordView.vue"),
    },
    {
      path: "/user-reset-password/:id/:token",
      name: "user-reset-password",
      component: () => import("../views/user/UserResetPasswordView.vue"),
    },
    {
      path: "/business-user-forgot-password",
      name: "business-user-forgot-password",
      component: () =>
        import("../views/business/user/BusinessUserForgotPasswordView.vue"),
    },
    {
      path: "/business-user-reset-password/:id/:token",
      name: "business-user-reset-password",
      component: () =>
        import("../views/business/user/BusinessUserResetPasswordView.vue"),
    },
    {
      path: "/activate-mailing/:email/:id/:token",
      name: "activate-mailing",
      component: () => import("../views/ActivateMailingView.vue"),
    },
    {
      path: "/unsubscribe-mailing/:email/:token",
      name: "unsubscribe-mailing",
      component: () => import("../views/UnsubscribeMailingView.vue"),
    },
    {
      path: "/unsubscribe-email/:email/:token",
      name: "unsubscribe-email",
      component: () => import("../views/UnsubscribeView.vue"),
    },
    {
      path: "/unsubscribe/:id/:token",
      name: "unsubscribe",
      component: () => import("../views/UnsubscribeView.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("../views/NotFoundView.vue"),
    },
  ],
});

export default router;
