<template>
  <div class="form-input-container flex flex-col justify-between h-full">
    <label
      v-if="!noLabel"
      :for="name"
      class="form-input-label crypto-talent-font-bold"
    >
      {{ label }}<span v-if="required" class="text-xs">*</span>
    </label>
    <div
      class="form-autocomplete-container crypto-talent-border"
      :class="{
        'form-autocomplete-background':
          showOptions && focused && optionsList.length > 0,
      }"
    >
      <input
        :id="name"
        v-model="value"
        type="search"
        :name="name"
        :placeholder="!noLabel ? placeholder : label"
        class="form-input"
        :data-cy="name"
        autocomplete="off"
        @focus="focused = true"
        @blur="focusOut"
        @keyup.enter="$emit('enter')"
      />
      <span @click="value = ''"><close-square-svg /></span>
      <transition name="fade">
        <ul
          v-if="showOptions && focused && optionsList.length > 0"
          class="form-autocomplete-list crypto-talent-border"
        >
          <li
            class="form-autocomplete-list-item form-autocomplete-list-item-hint"
          >
            <div class="form-autocomplete-list-item-hint-divider"></div>
            <div class="form-autocomplete-list-item-hint-content">
              <span> {{ $t("pages.home.recent") }}</span>
              <span
                class="form-autocomplete-list-item-hint-remove"
                @click="clearRecent"
              >
                {{ $t("shared.removeAll") }}
                <trash-svg class="inline crypto-talent-svg-small" />
              </span>
            </div>
          </li>
          <li
            v-for="item in optionsList"
            :key="item.value"
            :data-cy="`${name}-option-${item.label.toLowerCase()}`"
            class="form-autocomplete-list-item"
            @click="value = item[selectedValue]"
          >
            <span>
              <search-normal-svg />
              {{ item.label }} lorem ipsum dolor sit amet, consectetur
            </span>
            <trash-svg
              class="crypto-talent-svg-small"
              @click="clearSingle($event, item)"
            />
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import closeSquareSvg from "@/assets/svg/Svg/All/linear/close-square.svg?component";
import trashSvg from "@/assets/svg/Svg/All/linear/trash.svg?component";
import searchNormalSvg from "@/assets/svg/Svg/All/linear/search-normal.svg?component";

export default defineComponent({
  components: {
    closeSquareSvg,
    trashSvg,
    searchNormalSvg,
  },
  props: {
    modelValue: { type: String, required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: true },
    placeholder: { type: String, required: true },
    options: { type: Array, required: false, default: () => [] },
    selectedValue: { type: String, required: false, default: "label" },
    required: { type: Boolean, required: false, default: false },
    noLabel: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue", "clearRecent", "enter"],
  data() {
    return {
      value: this.modelValue,
      focused: false,
    };
  },
  computed: {
    optionsList(): any[] {
      if (this.value.length === 0) {
        return [];
      }
      return this.options
        .filter((option: any) =>
          option.label.toLowerCase().includes(this.value.toLowerCase()),
        )
        .slice(0, 3);
    },
    showOptions() {
      return this.options.length > 0 && this.value.length > 0;
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },
  methods: {
    focusOut() {
      setTimeout(() => {
        this.focused = false;
      }, 200);
    },
    clearRecent() {
      localStorage.removeItem("recentVacatures");
      this.$emit("clearRecent");
    },
    clearSingle(event: MouseEvent, item: any) {
      event.stopPropagation();
      const recentVacatures = JSON.parse(
        localStorage.getItem("recentVacatures") || "[]",
      );
      const filtered = recentVacatures.filter(
        (vacature: any) => vacature !== item.value,
      );
      localStorage.setItem("recentVacatures", JSON.stringify(filtered));
      this.$emit("clearRecent");
    },
  },
});
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside, vue-scoped-css/no-unused-selector
.form-autocomplete {
  &-background {
    background: rgb(35, 9, 56);

    .form-input {
      background: none;
    }
  }

  &-container {
    border-radius: 14px;
    position: relative;
    height: 3.25rem;

    transition: all 0.2s ease-in-out;

    & > input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      height: 100%;
    }

    & > span {
      position: absolute;
      right: 0.8rem;
      top: 50%;
      transform: translateY(-50%);

      opacity: 0.4;

      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &-list {
    position: absolute;
    top: calc(100% - 0.7rem);
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    padding-top: 1em;
    background-color: rgb(35, 9, 56);
    border-radius: 0 0 14px 14px;
    width: calc(100% - 4px);
    z-index: 100;
    box-shadow: none;

    @media screen and (max-width: 768px) {
      top: calc(100% - 10px);
      width: 100%;
    }

    &::after {
      background: none;

      @media screen and (max-width: 768px) {
        padding-top: 0;
        inset: -1px 0 0 0;
        border-radius: 0 0 14px 14px;
        box-shadow: none;
        background: linear-gradient(
          91.67deg,
          rgba(19, 227, 231, 0.9) 1.6%,
          rgba(167, 19, 251, 0.9) 102.63%
        );
      }
    }

    // eslint-disable-next-line vue-scoped-css/require-selector-used-inside
    &-item {
      display: flex;
      justify-content: space-between;
      background-color: rgb(35, 9, 56);
      padding: 0.5rem 1rem;
      font-weight: 300;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        padding: 4px 1rem;
      }

      & > span {
        @media screen and (min-width: 0px) and (max-width: 1360px) {
          font-size: 0.6rem;
        }
        @media screen and (min-width: 1360px) and (max-width: 1600px) {
          font-size: 0.7rem;
        }
        @media screen and (min-width: 1600px) {
          font-size: 0.8rem;
        }

        max-width: 190px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        // eslint-disable-next-line vue-scoped-css/no-unused-selector
        & > svg {
          display: inline;
          transform: scale(0.8);

          @media screen and (min-width: 0px) and (max-width: 1360px) {
            transform: scale(0.65);
          }
        }
      }

      &:hover {
        background-color: rgba(55, 29, 76, 1);
      }

      &-hint {
        cursor: default;

        &-content {
          display: flex;
          justify-content: space-between;
          width: 100%;

          font-size: 0.8rem;
          color: rgba(255, 255, 255, 0.5);
        }

        &-divider {
          position: absolute;
          top: 0.6rem;
          width: 90%;
          left: 5%;
          background-color: #fff;
          height: 1px;
          opacity: 0.4;

          @media screen and (max-width: 1360px) {
            opacity: 0.9;
          }
        }

        &-remove {
          opacity: 0.4;

          cursor: pointer;

          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 1;
          }
        }

        &:hover {
          background-color: rgba(35, 9, 56, 0.95);
        }
      }
    }
  }
}
</style>
