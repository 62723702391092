<template>
  <div
    class="form-checkbox-container"
    :data-cy="value ? `${name}-${value}` : name"
    @click="check"
  >
    <input
      :id="(Array.isArray(modelValue) ? value : name) as string"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="isChecked"
      :aria-label="name"
    />
    <span>
      <tick-square-svg v-if="isChecked" class="w-6 h-6" />
      <square-svg v-else class="w-6 h-6" />
    </span>
    <label
      :for="(Array.isArray(modelValue) ? value : name) as string"
      class="form-input-label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import squareSvg from "@/assets/svg/Svg/All/linear/square.svg?component";
import tickSquareSvg from "@/assets/svg/Svg/All/linear/tick-square.svg?component";

export default defineComponent({
  components: {
    squareSvg,
    tickSquareSvg,
  },
  props: {
    modelValue: {
      type: [Array, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      checkValue: this.modelValue,
    };
  },
  computed: {
    isChecked() {
      return Array.isArray(this.modelValue)
        ? this.modelValue?.includes(this.value)
        : this.modelValue;
    },
  },
  watch: {
    modelValue() {
      this.checkValue = this.modelValue;
    },
  },
  methods: {
    check(e: MouseEvent) {
      if (e.target instanceof HTMLInputElement) {
        if (!Array.isArray(this.modelValue)) {
          this.checkValue = e.target.checked;
          return this.$emit("update:modelValue", e.target.checked);
        }
        if (e.target.checked) {
          const filteredModelValue = this.modelValue.filter(
            (v: any) => v !== (e.target as HTMLInputElement).value,
          );

          const newValue = [...filteredModelValue, e.target.value];

          this.checkValue = newValue;
          this.$emit("update:modelValue", newValue);
        } else {
          const newValue = this.modelValue.filter(
            (v: any) => v !== (e.target as HTMLInputElement).value,
          );

          this.checkValue = newValue;
          this.$emit("update:modelValue", newValue);
        }
        return;
      }
      e.preventDefault();

      if (!Array.isArray(this.modelValue)) {
        this.checkValue = !this.modelValue;
        return this.$emit("update:modelValue", !this.modelValue);
      }

      let newValue: any[];

      if (this.modelValue.includes(this.value)) {
        newValue = this.modelValue.filter((v: any) => v !== this.value);
      } else {
        newValue = [...this.modelValue, this.value];
      }

      this.checkValue = newValue;
      this.$emit("update:modelValue", newValue);
    },
  },
});
</script>

<style lang="scss" scoped>
.form-checkbox-container {
  position: relative;

  display: flex;
  align-items: center;
  padding-left: 2rem;

  cursor: pointer;

  & > label {
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 11px;
    }
  }

  & > input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    opacity: 0;
    cursor: pointer;

    z-index: -1;
  }

  & > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}
</style>
