<template>
  <div class="relative w-screen flex justify-center">
    <div class="quick-search-row-container crypto-talent-container w-fit">
      <div
        class="quick-search-row__row grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-4 xs:grid-cols-2 grid-cols-1 lg:gap-[70px] md:gap-10"
      >
        <div class="quick-search-row__col sm:col-span-2">
          <header>{{ $t("nav.footer.jobOffers.location") }}</header>
          <div class="grid grid-cols-2">
            <ul class="quick-search-row-list">
              <li
                v-for="location in locations.slice(0, locations.length / 2)"
                :key="location"
                class="quick-search-row-list-item"
                data-cy="quick-search-row-location-list-item"
                @click="
                  $router.push(
                    '/job-offers?location=' + location + '&distance=10',
                  )
                "
              >
                {{ location }}
              </li>
            </ul>
            <ul class="quick-search-row-list">
              <li
                v-for="location in locations.slice(locations.length / 2)"
                :key="location"
                class="quick-search-row-list-item"
                data-cy="quick-search-row-location-list-item"
                @click="
                  $router.push(
                    '/job-offers?location=' + location + '&distance=10',
                  )
                "
              >
                {{ location }}
              </li>
            </ul>
          </div>
        </div>
        <div class="quick-search-row__col">
          <header>{{ $t("nav.footer.jobOffers.employmentForm") }}</header>
          <ul class="quick-search-row-list">
            <li
              v-for="employmentForm in employmentForms"
              :key="employmentForm.value"
              class="quick-search-row-list-item"
              data-cy="quick-search-row-employment-form-list-item"
              @click="
                $router.push(
                  '/job-offers?employmentForm=' + employmentForm.value,
                )
              "
            >
              {{ employmentForm.label }}
            </li>
          </ul>
        </div>

        <div class="quick-search-row__col">
          <header>{{ $t("nav.footer.jobOffers.education") }}</header>
          <ul class="quick-search-row-list">
            <li
              v-for="education in educationalLevels"
              :key="education.value"
              class="quick-search-row-list-item"
              data-cy="quick-search-row-education-list-item"
              @click="$router.push('/job-offers?education=' + education.value)"
            >
              {{ education.label }}
            </li>
          </ul>
        </div>

        <div class="quick-search-row__col">
          <header>{{ $t("nav.footer.jobOffers.profession") }}</header>
          <ul class="quick-search-row-list">
            <li
              v-for="branch in branchOptions"
              :key="branch.value"
              class="quick-search-row-list-item"
              data-cy="quick-search-row-branch-list-item"
              @click="$router.push('/job-offers?branch=' + branch.value)"
            >
              {{ branch.label }}
            </li>
          </ul>
        </div>
      </div>
      <button
        class="crypto-talent-border sm:w-[200px] w-full relative md:h-[52px] h-[50px] md:mt-9 mt-4 mx-auto rounded-[14px]"
        data-cy="quick-search-all-button"
        @click="$router.push('/job-offers')"
      >
        {{ $t("nav.footer.allJobOffers") }}
      </button>
    </div>
    <div class="quick-search-first-ellipse"></div>
    <div class="quick-search-second-ellipse"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import branches from "@/data/branches";
import employmentForms from "@/data/employmentForms";
import educationalLevels from "@/data/educationalLevels";

import type { StringOption } from "global";

export default defineComponent({
  name: "QuickSearchRow",
  data() {
    return {
      locations: [
        "Almere",
        "Amersfoort",
        "Amstelveen",
        "Amsterdam",
        "Apeldoorn",
        "Arnhem",
        "Breda",
        "Den Bosch",
        "Den Haag",
        "Dordrecht",
        "Ede",
        "Eindhoven",
        "Emmen",
        "Enschede",
        "Groningen",
        "Haarlem",
        "Haarlemmermeer",
        "Hilversum",
        "Leiden",
        "Lelystad",
        "Maastricht",
        "Nijmegen",
        "Rotterdam",
        "Tilburg",
        "Utrecht",
        "Zaanstad",
        "Zoetermeer",
        "Zwolle",
      ],
      branches: branches(this.$t),
      employmentForms: employmentForms(this.$t),
      educationalLevels: educationalLevels,
    };
  },
  computed: {
    branchOptions(): StringOption[] {
      return Array.from(this.branches)
        .sort(() => (Math.random() < 0.5 ? -1 : 1))
        .slice(0, 12);
    },
  },
});
</script>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/require-selector-used-inside, vue-scoped-css/no-unused-selector
.quick-search {
  &-row {
    &-container {
      position: relative;
      padding: 6rem 0;
      height: calc(100% - 12rem);
      z-index: 3;

      @media screen and (max-width: 768px) {
        height: 100%;
        max-width: 100%;
        padding-top: 12px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
      }

      header {
        margin-bottom: 2rem;
        font-family: "Red Hat Display 600", sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.1rem;
        line-height: 1.5rem;

        @media screen and (max-width: 768px) {
          margin-top: 11px;
          margin-bottom: 0px;
          font-size: 18px;
          line-height: 18px;
        }
      }

      & > .quick-search-row__row {
        max-width: 1200px;
        gap: 70px;

        & > .quick-search-row__col {
          @media screen and (max-width: 1080px) {
            flex: 100%;
          }
        }
      }
    }

    &-list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 22px;

      @media screen and (max-width: 768px) {
        margin-top: 11px;
        margin-bottom: 0px;
      }

      &-item {
        font-size: 14px;
        line-height: 20px;

        margin-right: 6px;
        font-weight: 300;
        cursor: pointer;
        transition: color 0.2s ease-in-out;

        @media screen and (max-width: 768px) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
          margin-right: 20px;
          font-size: 14px;
          line-height: 19px;
        }

        &:hover {
          color: #13e3e7;
        }
      }
    }
  }

  &-first-ellipse {
    position: absolute;
    width: 430.81px;
    height: 431.48px;
    left: 60px;
    top: 104px;
    background: #1e29fe;
    filter: blur(162px);
    transform: matrix(0.31, 0.95, -0.94, 0.34, 0, 0);
    opacity: 0.65;

    z-index: -1;
  }

  &-second-ellipse {
    position: absolute;
    width: 401.88px;
    height: 385.92px;
    right: 260px;
    top: 155px;
    background: #b500fd;
    filter: blur(167px);
    opacity: 0.75;

    z-index: -1;
  }
}
</style>
