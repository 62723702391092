<template>
  <div class="background-glow-container">
    <div class="background-glow"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackgroundGlow",
});
</script>

<style lang="scss" scoped>
.background-glow-container {
  position: relative;
}

.background-glow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("@/assets/images/ellipse/glow-blur.webp");
  background-size: cover;
  width: 100vw;
  max-width: 1440px;
  aspect-ratio: 1440 / 1197;
  height: auto;

  @media screen and (min-width: 1440px) {
    filter: blur(80px);
  }

  @media screen and (max-width: 1440px) {
    margin-top: -5vw;
    filter: blur(40px);
  }

  @media screen and (min-width: 1920px) {
    margin-top: -10vw;
  }
}
</style>
