<template>
  <div class="flex flex-col justify-center items-center md:px-0 px-2">
    <div
      class="home-view-search-card crypto-talent-container z-50 md:mt-14 mt-8 md:w-full w-[calc(100vw-32px)] max-w-[1034px] min-h-[245px] md:p-6 p-4 rounded-[25px] border border-white backdrop-blur-lg"
    >
      <div class="ml-auto w-fit relative md:block hidden">
        <transition name="fade">
          <div
            v-if="mailingModalToggle && !userMailToggle"
            class="home-view-search-card-email-popup py-2 px-3 absolute xl:left-3 xl:right-auto right-28 xl:rounded-br-[20px] xl:rounded-bl-none rounded-bl-[20px] rounded-t-[20px]"
            data-cy="mailing-email-popup"
            @click.stop
          >
            <div class="flex justify-between items-center">
              <div class="font-semibold">
                {{ $t("pages.home.emailLabel") }}
              </div>

              <close-square-svg
                class="crypto-talent-svg-medium crypto-talent-hover mr-1"
                @click="mailingModalToggle = false"
              />
            </div>

            <form-input
              v-model="email"
              name="email"
              :label="$t('user.email')"
              :placeholder="`${$t('user.email')}...`"
              class="form-input-small crypto-talent-border mt-2"
              small
              mono
            />

            <div
              class="crypto-talent-highlight underline font-semibold mt-2 mr-2 ml-auto w-fit"
              :class="{
                'opacity-50': !validators.email.test(email),
                'crypto-talent-hover ': validators.email.test(email),
              }"
              @click="closeMailingModal"
            >
              {{ $t("shared.save") }}
            </div>
          </div>
        </transition>
        <form-checkbox
          v-model="mailingToggle"
          name="mailing"
          :label="$t('pages.home.emailCheckboxLabel')"
          @click.stop
        />
      </div>
      <div
        class="md:mt-8 mt-0 flex md:flex-row flex-col md:justify-center md:items-end items-center w-full gap-4"
      >
        <keywords-autocomplete
          v-model="keywords"
          name="keywords"
          :label="$t('pages.home.keywordsLabel')"
          :placeholder="$t('pages.home.filters.keywords')"
          type="search"
          :options="recentList"
          class="xl:w-[300px] lg:w-[200px] md:w-[180px] w-full"
          @clear-recent="clearRecentList"
        />

        <div class="xl:w-[510px] md:w-[300px] w-full">
          <div
            class="col-span-4 font-semibold self-start md:text-lg text-sm md:leading-6 leading-6"
          >
            {{ $t("pages.home.locationLabel") }}
          </div>
          <div class="home-view-search-card-dual-select">
            <form-autocomplete-cities
              v-model="location"
              name="location"
              :placeholder="$t('pages.home.filters.city')"
              class="col-span-3 flex-1"
              css-class="home-location-input"
              css-class-label="crypto-talent-font-bold"
              css-class-container="crypto-talent-border home-location-input-container"
            />
            <distance-select
              v-model="distance"
              name="distance"
              :placeholder="$t('pages.home.distanceLabel')"
              :options="distanceOptions"
              class="flex-1"
              css-class-input="rounded-l-none"
            />
          </div>
        </div>

        <primary-button
          type="search"
          class="font-semibold lg:w-[200px] md:w-[180px] w-full h-[50px] md:mt-0 mt-[14px]"
          data-cy="home-view-search-button"
          @button-click="search"
        >
          <search-normal-svg class="inline mr-2 crypto-talent-svg-medium" />
          {{ $t("shared.search") }}
        </primary-button>
      </div>

      <div class="md:mt-[30px] mt-6 flex justify-between">
        <div class="relative md:hidden block">
          <transition name="fade">
            <div
              v-if="mailingModalToggle && !userMailToggle"
              class="home-view-search-card-email-popup py-2 px-3 absolute left-3 rounded-r-[20px] rounded-t-[20px]"
              data-cy="mailing-email-popup"
              @click.stop
            >
              <div class="flex justify-between items-center">
                <div class="font-semibold">
                  {{ $t("pages.home.emailLabel") }}
                </div>

                <close-square-svg
                  class="crypto-talent-svg-medium crypto-talent-hover mr-1"
                  @click="mailingModalToggle = false"
                />
              </div>

              <form-input
                v-model="email"
                name="email-mobile"
                :label="$t('user.email')"
                :placeholder="`${$t('user.email')}...`"
                class="form-input-small crypto-talent-border mt-1"
                small
                mono
              />

              <div
                class="crypto-talent-highlight underline mt-1 font-semibold w-fit"
                :class="{
                  'opacity-50': !validators.email.test(email),
                  'crypto-talent-hover ': validators.email.test(email),
                }"
                @click="closeMailingModal"
              >
                {{ $t("shared.save") }}
              </div>
            </div>
          </transition>
          <form-checkbox
            v-model="mailingToggle"
            name="mailing-mobile"
            :label="$t('pages.home.emailCheckboxLabel')"
            class="home-view-search-card-mobile-checkbox"
            @click.stop
          />
        </div>
        <link-button
          class="crypto-talent-hover md:block hidden"
          data-cy="expanded-search"
          @button-click="$router.push('/sign-up')"
        >
          <document-upload-svg class="inline mr-1 crypto-talent-svg-medium" />
          {{ $t("nav.uploadResume") }}
        </link-button>

        <link-button
          class="crypto-talent-hover"
          data-cy="expanded-search"
          @button-click="$router.push('/job-offers/advanced')"
        >
          {{ $t("pages.home.expandedSearch") }}
        </link-button>

        <link-button
          class="crypto-talent-hover crypto-talent-highlight md:block hidden"
          data-cy="home-all-job-offers"
          @button-click="$router.push('/job-offers')"
        >
          {{ $t("pages.home.allJobOffers") }}
        </link-button>
      </div>

      <link-button
        class="crypto-talent-hover crypto-talent-highlight mt-2 mx-auto w-fit md:hidden block"
        @button-click="$router.push('/job-offers')"
      >
        {{ $t("pages.home.allJobOffers") }}
      </link-button>
    </div>
    <link-button
      v-if="$root.isTablet"
      class="crypto-talent-hover w-fit mt-[14px] ml-7 mr-auto z-40 tracking-widest"
      data-cy="expanded-search"
      @button-click="$router.push('/sign-up')"
    >
      <document-upload-svg class="inline mr-1 crypto-talent-svg-medium" />
      {{ $t("nav.uploadResume") }}
    </link-button>
    <teleport v-if="modalToggle" to=".crypto-talent-app">
      <alert-modal
        v-if="modalToggle"
        :title="$t('error.generic')"
        :message="errorMessage"
        type="info"
        @dismiss="modalToggle = false"
      ></alert-modal>
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import httpClient, { getUser } from "@/http_client";

import KeywordsAutocomplete from "./HomeKeywordsAutocomplete.vue";
import DistanceSelect from "./HomeDistanceSelect.vue";

import validators from "@/data/validators";
import distances from "@/data/distances";

import searchNormalSvg from "@/assets/svg/Svg/All/linear/search-normal.svg?component";
import closeSquareSvg from "@/assets/svg/Svg/All/linear/close-square.svg?component";
import documentUploadSvg from "@/assets/svg/Svg/All/linear/document-upload.svg?component";

import type { StringOption } from "global";

export default defineComponent({
  name: "HomeSearchRow",
  components: {
    KeywordsAutocomplete,
    DistanceSelect,
    searchNormalSvg,
    closeSquareSvg,
    documentUploadSvg,
  },
  data() {
    return {
      keywords: "",
      location: "",
      distance: "10",
      mailingToggle: false,
      email: "",
      mailingModalToggle: false,
      userMailToggle: false,
      recentList: [] as StringOption[],
      errorMessage: "",
      modalToggle: false,
      validators,
    };
  },
  computed: {
    distanceOptions(): StringOption[] {
      return distances(this.$t);
    },
  },
  watch: {
    mailingToggle(): void {
      if (!this.mailingToggle) {
        this.mailingModalToggle = false;
        this.userMailToggle = false;
        this.email = "";
        return;
      }

      if (this.mailingToggle && localStorage.getItem("userToken")) {
        this.userMailToggle = true;
      }

      if (this.mailingToggle) {
        this.mailingModalToggle = true;
      }
    },
  },
  async mounted() {
    await this.initRecentList();
  },
  methods: {
    async search(): Promise<void> {
      const searchParams = new URLSearchParams();

      if (this.keywords) {
        searchParams.append("keywords", this.keywords);
      }

      if (this.location) {
        searchParams.append("city", this.location);
      }

      if (this.location && this.distance) {
        searchParams.append("distance", String(this.distance));
      }

      if (this.mailingToggle && (this.keywords || this.location)) {
        if (this.userMailToggle) {
          const { data: mailingResponse } = await httpClient.post(
            "/mailing/user",
            {
              query: {
                keywords: this.keywords,
                ...(this.location
                  ? { city: this.location, distance: this.distance }
                  : {}),
              },
            },
          );
          searchParams.append("mailing", mailingResponse.type);
        } else if (this.email && validators.email.test(this.email)) {
          const { data: mailingResponse } = await httpClient.post(
            "/mailing/email",
            {
              email: this.email.toLowerCase(),
              query: {
                keywords: this.keywords,
                ...(this.location
                  ? { city: this.location, distance: this.distance }
                  : {}),
              },
            },
          );
          searchParams.append("mailing", mailingResponse.type);
        } else {
          this.errorMessage = "Invalid email";
          this.modalToggle = true;
          return;
        }
      }

      this.$router.push(`/job-offers?${searchParams.toString()}`);
    },
    clearRecentList(): void {
      this.recentList = [];

      localStorage.removeItem("recentVacatures");
    },
    async initRecentList(): Promise<void> {
      const recentTitles = JSON.parse(
        localStorage.getItem("recentVacatures") || "[]",
      );

      this.recentList = recentTitles
        .reverse()
        .slice(0, 12)
        .map((title: string) => ({ label: title, value: title }));
    },
    closeMailingModal(): void {
      if (validators.email.test(this.email)) {
        this.mailingModalToggle = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.home-view-search-card {
  background-color: rgba(181, 0, 253, 0.3);
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px -23px 85px rgba(219, 127, 255, 0.54));

  @media screen and (max-width: 768px) {
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
  }

  &-email-popup {
    bottom: calc(100% + 0.125rem);
    min-width: 18rem;
    border: 1px solid rgba(181, 0, 253, 0.8);
    background: rgba(181, 0, 253, 0.4);
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01em;
    z-index: 150;

    @media screen and (max-width: 430px) {
      top: 100%;
      bottom: auto;
      left: 12px;
      background: rgba(131, 0, 203, 0.8);
      border-radius: 0 20px 20px 20px;
    }
  }

  // eslint-disable-next-line vue-scoped-css/require-selector-used-inside
  &-dual-select {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 768px) {
      display: flex;
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.home-view-search-card-dual-select .distance-select-text {
  font-size: 12px !important;
}

.home-view-search-card-mobile-checkbox {
  padding-left: 24px !important;

  & > span > svg {
    margin-top: 2px;

    & > path {
      transform: scale(0.8);
    }
  }

  & > .form-input-label {
    font-weight: 400;
  }
}
</style>
