<template>
  <div
    class="navbar-wrapper relative flex flex-col items-center w-screen bg-[#0c0015]"
  >
    <div
      class="navbar-primary-wrapper w-screen flex items-center justify-center"
    >
      <div
        class="navbar-primary-container sm:container justify-between flex items-center w-full gap-x-2"
      >
        <div class="md:hidden w-8 flex justify-center items-center">
          <div v-if="navbarType === 'business'" class="relative">
            <menu-svg @click="mobileDashboardDrawer = !mobileDashboardDrawer" />
            <transition name="fade">
              <div
                v-if="mobileDashboardDrawer"
                class="navbar-mobile-menu-popover z-50"
                @click.stop
              >
                <div class="navbar-mobile-menu-popover-triangle"></div>
                <div class="navbar-mobile-menu-popover-list">
                  <div
                    class="navbar-mobile-menu-popover-list-item crypto-talent-hover"
                    @click="mobileRoute('/business-dashboard')"
                  >
                    <home-svg class="crypto-talent-svg-medium" />
                    {{ $t("nav.business.dashboard") }}
                    <div class="navbar-mobile-menu-popover-list-item-arrow">
                      <arrow-right-svg class="crypto-talent-svg-small" />
                    </div>
                  </div>
                  <div
                    class="navbar-mobile-menu-popover-list-item crypto-talent-hover"
                    @click="mobileRoute('/business-dashboard/job-offers')"
                  >
                    <briefcase-svg class="crypto-talent-svg-medium" />
                    {{ $t("nav.business.jobOffers") }}
                    <div class="navbar-mobile-menu-popover-list-item-arrow">
                      <arrow-right-svg class="crypto-talent-svg-small" />
                    </div>
                  </div>
                  <div
                    class="navbar-mobile-menu-popover-list-item crypto-talent-hover"
                    @click="mobileRoute('/business-dashboard/applications')"
                  >
                    <document-previous-svg class="crypto-talent-svg-medium" />
                    {{ $t("nav.business.applications") }}
                    <div class="navbar-mobile-menu-popover-list-item-arrow">
                      <arrow-right-svg class="crypto-talent-svg-small" />
                    </div>
                  </div>
                  <div
                    class="navbar-mobile-menu-popover-list-item crypto-talent-hover"
                    @click="mobileRoute('/business-dashboard/candidates')"
                  >
                    <people-svg class="crypto-talent-svg-medium" />
                    {{ $t("nav.business.candidates") }}
                    <div class="navbar-mobile-menu-popover-list-item-arrow">
                      <arrow-right-svg class="crypto-talent-svg-small" />
                    </div>
                  </div>
                </div>
                <div
                  class="navbar-mobile-menu-popover-bottom"
                  @click="mobileRoute('/business-dashboard/logo')"
                >
                  {{ $t("nav.business.logo") }}
                  <arrow-right-svg class="crypto-talent-svg-small" />
                </div>
              </div>
            </transition>
            <div
              v-if="mobileDashboardDrawer"
              class="fixed top-0 left-0 w-full h-full z-40"
              @click="mobileDashboardDrawer = false"
            />
          </div>
          <slot v-else name="mobile"></slot>
        </div>
        <img
          src="@/assets/images/navbar-logo.png"
          alt="Cryptotalent"
          class="navbar-logo xl:h-full md:h-8 h-6 max-h-[2.8125rem] md:w-auto w-28 max-w-[12.5rem] cursor-pointer"
          @click="$router.push('/')"
        />
        <slot></slot>
        <div class="flex items-center md:gap-x-3 gap-x-2">
          <div
            v-if="navbarType === 'default' && !small"
            class="flex justify-end gap-x-3"
          >
            <div class="relative">
              <secondary-button
                v-if="!hideRegister"
                class="md:block hidden w-36 h-12"
                style="min-width: 7rem"
                @mouseenter="hoverCreateAccount"
                @button-click="$router.push('/sign-up')"
              >
                {{ $t("nav.createAccount") }}
              </secondary-button>
              <transition name="fade">
                <div v-if="createAccountHoverToggle" class="md:block hidden">
                  <div class="navbar-create-account-popup-triangle"></div>
                  <div
                    class="absolute left-1/2 -translate-x-1/2 bg-white top-[calc(100%+14px)] w-44 min-h-12 py-2 px-3 text-[#000000] flex justify-center items-center rounded-[14px] text-sm text-center"
                  >
                    {{ $t("nav.createAccountHint1") }} <br />
                    {{ $t("nav.createAccountHint2") }}
                  </div>
                </div>
              </transition>
            </div>
            <primary-button
              v-if="!hideLogin"
              class="md:block hidden font-semibold w-36 h-12"
              @button-click="$router.push('/login')"
            >
              {{ $t("nav.login") }}
            </primary-button>
            <div class="md:hidden block" @click="$router.push('/login')">
              <user-svg class="crypto-talent-svg-medium" />
            </div>
          </div>
          <div
            v-else-if="
              navbarType === 'user' &&
              !small &&
              !(hideLogin && !$root.isHorizontalMobile)
            "
            class="flex justify-end"
          >
            <nav-bar-drawer to="/user-profile" :title="computedUsername">
              <nav-bar-drawer-link to="/user-profile">
                {{ $t("nav.profile") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/user-mailing">
                {{ $t("nav.user.mailing") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/user-favorites">
                {{ $t("nav.user.favorites") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/user-applications">
                {{ $t("nav.user.applications") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/user-settings">
                {{ $t("nav.user.profileSettings") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/logout" class="justify-center">
                {{ $t("nav.logout") }}
              </nav-bar-drawer-link>
            </nav-bar-drawer>
          </div>
          <div
            v-else-if="navbarType === 'business'"
            class="flex justify-end items-center"
          >
            <nav-bar-drawer to="/business-dashboard" :title="computedUsername">
              <nav-bar-drawer-link
                to="/business-dashboard"
                class="md:flex hidden"
              >
                {{ $t("nav.business.dashboard") }}
              </nav-bar-drawer-link>
              <div class="navbar-mobile-profile md:hidden flex">
                <div class="navbar-mobile-profile-avatar">
                  <user-svg />
                </div>
                <div
                  class="navbar-mobile-profile-content flex h-full flex-col gap-3"
                >
                  <div
                    class="whitespace-nowrap overflow-hidden max-w-[90px] overflow-ellipsis text-sm font-semibold"
                  >
                    {{ computedUsername }}
                  </div>
                  <div class="flex gap-x-[2px]">
                    <buy-crypto-svg />
                    {{
                      (user as BusinessUserFullyPopulated)?.business?.credits ||
                      0
                    }}
                  </div>
                </div>
              </div>
              <nav-bar-drawer-link
                v-if="(user as BusinessUserFullyPopulated)?.admin || (user as BusinessUserFullyPopulated)?.admin"
                to="/business-dashboard/account"
              >
                {{ $t("nav.business.myAccount") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link
                v-if="(user as BusinessUserFullyPopulated)?.admin"
                to="/business-dashboard/manage-users"
              >
                {{ $t("nav.business.manage") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/business-dashboard/settings">
                {{ $t("nav.business.settings") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/business-dashboard/buy-credits">
                {{ $t("nav.business.credits") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/business-dashboard/cv-alert">
                {{ $t("nav.business.cvAlert") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link
                v-if="(user as BusinessUserFullyPopulated)?.admin || (user as BusinessUserFullyPopulated)?.marketing"
                to="/business-dashboard/appearance"
              >
                {{
                  $t("nav.business.profile", {
                    name: (user as BusinessUserFullyPopulated)?.business?.name,
                  })
                }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link
                v-if="(user as BusinessUserFullyPopulated)?.admin || (user as BusinessUserFullyPopulated)?.marketing"
                to="/business-dashboard/statistics"
              >
                {{ $t("nav.business.statistics") }}
              </nav-bar-drawer-link>
              <nav-bar-drawer-link to="/business-logout" class="justify-center">
                {{ $t("nav.logout") }}
              </nav-bar-drawer-link>
            </nav-bar-drawer>
            <div
              class="navbar-primary-business-coins xs:flex hidden"
              :title="$t('shared.cvTokens')"
            >
              <buy-crypto-svg />{{
                (user as BusinessUserFullyPopulated)?.business?.credits || 0
              }}
            </div>
          </div>
          <language-selector v-if="!$root.isTablet" class="w-[90px]" />
        </div>
      </div>
    </div>
    <div
      v-if="!small && !user"
      class="md:flex hidden justify-center w-screen max-h-[calc(12rem-8.25rem)]"
    >
      <div class="container flex justify-between items-center mx-2 py-1">
        <div>
          <nav-bar-link to="/business-login">
            {{ $t("nav.businessLogin") }}
          </nav-bar-link>
          <nav-bar-link to="/job-offer-create">
            {{ $t("nav.jobOffer") }}
          </nav-bar-link>
        </div>
        <div>
          <nav-bar-link to="/sign-up" class="flex items-center">
            <document-upload-svg class="inline mr-2" />{{
              $t("nav.uploadResume")
            }}
          </nav-bar-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { getUser, getBusinessUser, isLoggedInWithKey } from "@/http_client";

import LanguageSelector from "../LanguageSelector.vue";
import NavBarLink from "./NavBarLink.vue";
import NavBarDrawer from "./NavBarDrawer.vue";
import NavBarDrawerLink from "./NavBarDrawerLink.vue";

import documentUploadSvg from "@/assets/svg/Svg/All/linear/document-upload.svg?component";
import buyCryptoSvg from "@/assets/svg/Svg/All/linear/buy-crypto.svg?component";
import UserSvg from "@/assets/svg/Svg/All/linear/user.svg?component";
import MenuSvg from "@/assets/svg/Svg/All/linear/menu.svg?component";
import HomeSvg from "@/assets/svg/Svg/All/linear/home-2.svg?component";
import BriefcaseSvg from "@/assets/svg/Svg/All/linear/briefcase.svg?component";
import DocumentPreviousSvg from "@/assets/svg/Svg/All/linear/document-previous.svg?component";
import PeopleSvg from "@/assets/svg/Svg/All/linear/people.svg?component";
import ArrowRightSvg from "@/assets/svg/Svg/All/linear/arrow-right-1.svg?component";

import type { UserFullyPopulated } from "@/models/user";
import type { BusinessUserFullyPopulated } from "@/models/business.user";

export default defineComponent({
  components: {
    LanguageSelector,
    NavBarLink,
    NavBarDrawer,
    NavBarDrawerLink,
    documentUploadSvg,
    buyCryptoSvg,
    UserSvg,
    MenuSvg,
    HomeSvg,
    BriefcaseSvg,
    DocumentPreviousSvg,
    PeopleSvg,
    ArrowRightSvg,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    hideRegister: {
      type: Boolean,
      default: false,
    },
    hideLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null as UserFullyPopulated | BusinessUserFullyPopulated | null,
      mobileDashboardDrawer: false,
      createAccountHoverToggle: false,
    };
  },
  computed: {
    navbarType(): string {
      if (isLoggedInWithKey("UserAuthorization")) {
        return "user";
      }
      if (isLoggedInWithKey("BusinessAuthorization")) {
        return "business";
      }
      return "default";
    },
    computedUsername(): string {
      if (this.navbarType === "user") {
        const user = this.user as UserFullyPopulated;
        return user?.firstName && user?.lastName
          ? `${user.firstName} ${user.lastName}`
          : user?.email || "";
      } else if (this.navbarType === "business") {
        return (this.user as BusinessUserFullyPopulated)?.name || "";
      } else {
        return "";
      }
    },
  },
  async mounted() {
    try {
      if (this.navbarType === "user") {
        this.user = await getUser();
      } else if (this.navbarType === "business") {
        this.user = await getBusinessUser();
      } else {
        this.user = null;
      }
    } catch (error) {
      console.log("Not logged in...");
      localStorage.removeItem("userToken");
      localStorage.removeItem("businessToken");
      location.reload();
    }
  },
  methods: {
    mobileRoute(route: string) {
      this.$router.push(route);
      this.mobileDashboardDrawer = false;
    },
    hoverCreateAccount() {
      setTimeout(() => {
        if (localStorage.getItem("createAccountHoverToggle") === "true") {
          return;
        }

        this.createAccountHoverToggle = true;

        localStorage.setItem("createAccountHoverToggle", "true");

        setTimeout(() => {
          this.createAccountHoverToggle = false;
        }, 60000);
      }, 500);
    },
  },
});
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  background: linear-gradient(
    180deg,
    rgba(35, 9, 56, 0.7) 33.22%,
    rgba(12, 0, 21, 0.7) 100%
  );

  z-index: 100;

  @media print {
    display: none;
  }

  @media screen and (max-width: 430px) {
    background: none;
  }
}

// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.navbar-primary {
  &-wrapper {
    min-height: 8.125rem;
    background: linear-gradient(
      180deg,
      rgba(35, 9, 56, 0.9) 13.22%,
      rgba(12, 0, 21, 0.9) 100%
    );

    @media screen and (max-width: 768px) {
      min-height: 60px;
    }

    @media screen and (max-width: 640px) {
      min-height: 46px;
    }
  }

  &-container {
    max-height: 8.25rem;
  }

  &-business-coins {
    gap: 4px;
    margin-left: 16px;
    margin-right: 20px;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
  }
}

.navbar-mobile-menu-popover {
  position: absolute;
  top: 34px;
  left: -8px;
  width: calc(50vw - 1px);
  min-height: 345px;
  padding: 13px;
  background: rgba(120, 23, 184, 0.4);
  border-radius: 0 0 10px 0;
  backdrop-filter: blur(10px);

  &-triangle {
    position: absolute;
    top: -10px;
    left: 16px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(120, 23, 184, 0.4);
    backdrop-filter: blur(10px);
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-item {
      width: 100%;
      height: 40px;
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 9px;
      border-radius: 5px;
      background: rgba(35, 9, 56, 0.7);
      font-size: 12px;
      font-weight: 700;

      &-arrow {
        margin-left: auto;
      }
    }
  }

  &-bottom {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 55px;
    padding-left: 13px;
    padding-right: 13px;
    border-top: 1px solid #b500fd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
  }
}

.navbar-mobile-profile {
  height: 70px;
  gap: 12px;
  padding: 9px 13px;
  border-bottom: 1px solid #b500fd;

  &-avatar {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #b500fd;
    background: linear-gradient(120.74deg, #01fce5 -5.09%, #b500fd 102.65%);
  }

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
}

.navbar-create-account-popup-triangle {
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);

  width: 0;
  height: 0;

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}
</style>
