<template>
  <div
    class="home-view-latest-jobs crypto-talent-border relative w-full min-h-[380px] max-w-[1100px] pt-[18px] flex flex-col items-center rounded-[25px] px-2 md:mt-[143px] mt-[93px]"
  >
    <h3>{{ $t("pages.home.latestJobs") }}</h3>
    <span class="crypto-talent-dashboard-card-main-divider mt-5"></span>
    <div
      v-if="latestJobs.length === 0"
      class="crypto-talent-loading-ring md:scale-[150%] mx-auto mt-8"
    ></div>
    <div v-else class="home-view-latest-jobs-list-container">
      <div class="home-view-latest-jobs-list">
        <div
          v-for="vacature in latestJobs.slice(0, 6)"
          :key="vacature._id"
          class="home-view-latest-jobs-list-item crypto-talent-hover"
          data-cy="home-view-latest-jobs-list-item"
          @click="$router.push(`/job-offers/${vacature._id}`)"
        >
          {{ vacature.title }}
          <span class="crypto-talent-highlight"> ({{ vacature.count }}) </span>
        </div>
      </div>
      <div v-if="!$root.isTablet" class="home-view-latest-jobs-list">
        <div
          v-for="vacature in latestJobs.slice(6, 12)"
          :key="vacature._id"
          class="home-view-latest-jobs-list-item crypto-talent-hover"
          @click="$router.push(`/job-offers/${vacature._id}`)"
        >
          {{ vacature.title }}
          <span class="crypto-talent-highlight"> ({{ vacature.count }}) </span>
        </div>
      </div>
      <div v-if="!$root.isMobile" class="home-view-latest-jobs-list">
        <div
          v-for="vacature in latestJobs.slice(12, 18)"
          :key="vacature._id"
          class="home-view-latest-jobs-list-item crypto-talent-hover"
          @click="$router.push(`/job-offers/${vacature._id}`)"
        >
          {{ vacature.title }}
          <span class="crypto-talent-highlight"> ({{ vacature.count }}) </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { VacatureWithBusinessAndUser } from "@/models/vacature";

export default defineComponent({
  name: "LatestJobsRow",
  props: {
    latestJobs: {
      type: Array as () => (VacatureWithBusinessAndUser & { count: number })[],
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.home-view-latest-jobs {
  background: linear-gradient(
    180deg,
    rgba(35, 9, 56, 0.5) 13.22%,
    rgba(12, 0, 21, 0.5) 100%
  );
  backdrop-filter: blur(6.5px);
  text-align: center;
  z-index: 10;
  margin-left: 8px;
  margin-right: 8px;

  @media screen and (max-width: 430px) {
    min-height: auto;
    max-width: calc(100vw - 30px);
    background: rgba(35, 9, 56, 0.3);
    backdrop-filter: blur(6.5px);
  }

  &::after {
    border-radius: 25px;
    padding: 0.75px;
    left: 0.25px;
    top: 0.25px;
  }

  & > h3 {
    @media screen and (max-width: 430px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 25px;
    }

    @media screen and (min-width: 768px) and (max-width: 1300px) {
      font-size: 2.5rem;
    }
    @media screen and (min-width: 1300px) and (max-width: 1600px) {
      font-size: 3rem;
    }
    @media screen and (min-width: 1600px) {
      font-size: 3.5rem;
    }

    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }

  &-list {
    margin: 2rem 0;
    padding: 0.5rem;

    @media screen and (max-width: 430px) {
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 12px;
      font-size: 16px;
      font-weight: 700;
    }

    &-container {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 0 16px;

      @media screen and (max-width: 430px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &-item {
      width: fit-content;
      list-style-type: decimal;
      margin: 0.1rem 0;

      @media screen and (max-width: 430px) {
        font-size: 16px;
      }

      @media screen and (min-width: 768px) and (max-width: 1300px) {
        font-size: 0.8rem;
        line-height: 1.5rem;
      }

      @media screen and (min-width: 1300px) and (max-width: 1600px) {
        font-size: 1rem;
        line-height: 2rem;
      }

      @media screen and (min-width: 1600px) {
        font-size: 1.1rem;
        line-height: 2.5rem;
      }

      font-weight: 600;
    }
  }
}
</style>

<style lang="scss">
.home-view-latest-jobs::after {
  @media screen and (max-width: 430px) {
    opacity: 0.4;
  }
}
</style>
