<template>
  <div
    class="form-input-container"
    :class="{
      [cssClass]: true,
      'form-input-container-row': row,
      'form-autocomplete-container': !row,
      'form-input-mono-color': mono,
    }"
  >
    <label
      v-if="!small"
      :for="name"
      class="form-input-label crypto-talent-font-bold"
      :class="{
        [cssClassLabel]: true,
        'form-input-label-row': row,
      }"
    >
      {{ label }} <span v-if="required" class="text-xs text-red-500">*</span>

      <slot name="label"></slot>
    </label>
    <div
      class="form-autocomplete-inner-container crypto-talent-border"
      :class="{
        [cssClassContainer]: true,
        'form-input-row': row,
        'form-input-inner-container-readonly form-autocomplete-container-readonly':
          readonly,
      }"
    >
      <input
        :id="name"
        v-model="value"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        class="form-input"
        :class="{
          [cssClassInput]: true,
          'form-input-readonly': readonly,
        }"
        :data-cy="name"
        autocomplete="off"
        :disabled="readonly"
        @focus="focused = true"
        @blur="focusOut"
        @change="validate"
        @keyup.enter="navigateConfirm"
        @keyup.up="navigateUp"
        @keyup.down="navigateDown"
      />
      <div
        class="absolute right-3 top-1/2 -translate-y-1/2 opacity-40"
        :class="{
          'cursor-pointer  hover:opacity-100': !readonly,
        }"
        @click="clearValue"
      >
        <close-square-svg
          :class="{
            'crypto-talent-svg-medium': $root.isMobile,
          }"
        />
      </div>
      <ul
        v-if="showOptions && focused && options.length > 0"
        class="form-autocomplete-list"
        :class="{
          'form-autocomplete-list-row': row,
        }"
      >
        <li
          v-for="(item, index) in options"
          :key="item.value"
          :data-cy="`${name}-option-${item.label.toLowerCase()}`"
          class="form-autocomplete-list-item"
          :class="{
            'form-autocomplete-list-item__hover': selectedIndex === index,
          }"
          @click="onSelect(item.value)"
        >
          {{ item.label }}
        </li>
      </ul>
      <div
        v-if="validationError"
        class="form-input-validation-text"
        name="form-input-validation"
      >
        {{ validationErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import httpClient from "@/http_client";

import closeSquareSvg from "@/assets/svg/Svg/All/linear/close-square.svg?component";

export default defineComponent({
  components: {
    closeSquareSvg,
  },
  props: {
    modelValue: { type: String, required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    placeholder: { type: String, required: true },
    type: { type: String, required: false, default: "text" },
    cssClass: { type: String, required: false, default: "" },
    cssClassInput: { type: String, required: false, default: "" },
    cssClassLabel: { type: String, required: false, default: "" },
    cssClassContainer: { type: String, required: false, default: "" },
    small: { type: Boolean, required: false, default: false },
    selectedValue: { type: String, required: false, default: "label" },
    row: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    mono: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue", "value-change", "enter"],
  data() {
    return {
      value: this.modelValue,
      selectedIndex: -1,
      focused: false,
      timer: null as any,
      options: [] as { label: string; value: string }[],
      validationError: false,
      validationErrorMessage: "",
    };
  },
  computed: {
    showOptions() {
      return this.options.length > 0 && this.value.length > 0;
    },
  },
  watch: {
    value() {
      this.selectedIndex = -1;
      this.$emit("update:modelValue", this.value);

      if (this.value.length === 0) {
        this.options = [];
        return;
      }

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(async () => {
        if (this.value) {
          const { data: cities } = await httpClient.get(
            `/cities/${this.value}`,
          );
          this.options = cities.slice(0, 6);
        }
      }, 300);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    focusOut() {
      setTimeout(() => {
        this.focused = false;
      }, 200);
    },
    validate() {
      this.$emit("value-change", this.value);

      if (this.value === "" && this.required) {
        this.validationError = true;
        this.validationErrorMessage = this.$t("validation.required");
        return;
      }
      this.validationError = false;
      this.validationErrorMessage = "";
    },
    clearValue() {
      if (!this.readonly) {
        this.value = "";
      }
    },
    onSelect(value: string) {
      this.value = value;
      this.$emit("value-change", this.value);
    },
    navigateConfirm(event: any) {
      event.preventDefault();

      if (this.selectedIndex !== -1) {
        this.onSelect(this.options[this.selectedIndex].value);
        this.focused = false;
      }

      this.$emit("enter");
    },
    navigateUp(event: any) {
      event.preventDefault();

      if (this.selectedIndex !== 0) {
        this.selectedIndex--;
      }
    },
    navigateDown(event: any) {
      event.preventDefault();

      if (this.selectedIndex !== this.options.length - 1) {
        this.selectedIndex++;
      }
    },
  },
});
</script>
