<template>
  <div class="form-checkbox-group">
    <span v-if="label" class="w-44 text-sm font-semibold">
      {{ label }}
    </span>
    <div class="form-checkbox-group-options">
      <form-checkbox
        v-for="option in options"
        :key="option.value"
        v-model="value"
        :name="name"
        :label="option.label"
        :value="option.value"
        class="form-checkbox-group-option"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { StringOption } from "global";

export default defineComponent({
  props: {
    modelValue: {
      type: Array as () => string[],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array as () => StringOption[],
      required: true,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-checkbox-group {
  display: flex;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }

  &-options {
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 430px) {
      gap: 8px 0;
    }
  }

  &-option {
    width: calc(50% - 20px);
    padding-left: 30px;

    @media screen and (max-width: 430px) {
      flex: 1 50%;
    }
  }
}
</style>

<style lang="scss">
.form-checkbox-group-option {
  & svg {
    transform: scale(0.833);

    & > path {
      stroke: #01fce5;
    }
  }

  & > .form-input-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
