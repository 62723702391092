<template>
  <div class="text-center my-2 w-full" :class="cssClass">
    <label
      v-if="!small"
      :for="name"
      class="text-base font-semibold"
      :class="row ? 'my-auto w-1/3 inline-block text-right pr-4' : ''"
    >
      {{ label }}<span v-if="required" class="text-xs">*</span>
    </label>
    <input
      v-model="value"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      class="px-4 py-2 border rounded border-gray-600 my-0 text-center text-xl"
      :class="row ? 'w-2/3' : 'w-full'"
      :data-cy="name"
      @blur="validateDate"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  props: {
    modelValue: { type: [String, Number], required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: true },
    placeholder: { type: String, required: true },
    type: { type: String, required: false, default: "text" },
    cssClass: { type: String, required: false, default: "" },
    required: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
    row: { type: Boolean, required: false, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.getStartDate(),
    };
  },
  watch: {
    value() {
      this.$emit(
        "update:modelValue",
        moment(this.value, "DD MMM YYYY").format("YYYY-MM-DD"),
      );
    },
  },
  methods: {
    validateDate() {
      const date = moment(this.value, ["YYYY-MM-DD", "DD-MM-YYYY"]);
      if (!date.isValid()) {
        return (this.value = "");
      }

      this.value = date.format("DD MMM YYYY");
    },
    getStartDate() {
      const date = moment(this.value, ["YYYY-MM-DD", "DD-MM-YYYY"]);

      if (!date.isValid()) {
        return "";
      }

      return date.format("DD MMM YYYY");
    },
  },
});
</script>
