<template>
  <div class="alert-modal-overlay">
    <div
      class="alert-modal crypto-talent-border"
      style="min-width: 20rem; aspect-ratio: 2/0.9"
      data-cy="alert-modal"
    >
      <span class="font-semibold text-xl">
        {{ title }}
      </span>
      <p class="text-lg text-center">
        {{ getMessage() }}
      </p>
      <primary-button
        class="alert-modal-button font-semibold"
        @button-click="$emit('dismiss')"
      >
        {{ $t("shared.ok") }}
      </primary-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "info",
    },
  },
  emits: ["dismiss"],
  methods: {
    getMessage() {
      if (this.message.includes("duplicate key")) {
        if (this.message.includes("email")) {
          return this.$t("info.user_exists");
        }
        if (
          this.message.includes("name") &&
          this.message.includes("businesses")
        ) {
          return this.$t("info.business_name_exists");
        }
        if (this.message.includes("phone")) {
          return this.$t("info.phone_exists");
        }
      }

      if (this.$te(`${this.type}.${this.parseMessageToLabel(this.message)}`)) {
        return this.$t(
          `${this.type}.${this.parseMessageToLabel(this.message)}`,
        );
      }

      if (this.type === "error") {
        return this.$t("error.generic");
      }

      return this.message;
    },
    parseMessageToLabel(message: string): string {
      if (message.includes(" ")) {
        return message.split(" ").join("_").toLowerCase();
      }
      return message.toLowerCase();
    },
  },
});
</script>

<style lang="scss" scoped>
.alert-modal {
  max-width: 32rem;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(181, 0, 253, 0.3);
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);

  &-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(28, 0, 51, 0.7);
    z-index: 500;
  }

  &-button {
    margin-top: 1rem;
    width: 156px;
    height: 40px;

    border-radius: 14px;
  }
}
</style>
