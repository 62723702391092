<template>
  <div class="flex items-center gap-x-3 md:flex-row flex-col">
    <div class="relative h-fit w-full">
      <form-input
        :placeholder="filename"
        class="w-full"
        :class="{
          'form-input-small': small,
        }"
        mono
        name="apply-platform-cv-filename"
        disabled
      />
      <cross-svg
        v-if="value"
        class="absolute top-1/2 -translate-y-1/2 right-3 crypto-talent-hover"
        @click="value = ''"
      />
    </div>
    <primary-button
      class="md:w-48 w-full md:mt-0 mt-3 font-semibold text-xs"
      :style="{
        height: small ? '40px' : '50px',
      }"
      @button-click="uploadDocument"
    >
      {{
        value
          ? $t("shared.change")
          : $t("pages.vacature.list.apply.platform.uploadCVButton")
      }}
    </primary-button>
    <input
      :id="id"
      ref="documentUpload"
      type="file"
      accept="application/pdf"
      data-cy="signup-cv-upload-input"
      class="hidden"
      @change="onDocumentUpload"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CrossSvg from "@/assets/svg/form/step4/cross.svg?component";

export default defineComponent({
  name: "FormUpload",
  components: {
    CrossSvg,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "update:filename"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
    errorFileSize() {
      return this.value && this.value.length > 100;
    },
  },
  methods: {
    uploadDocument() {
      (this.$refs as any).documentUpload.click();
    },
    onDocumentUpload(event: Event) {
      const target = event.target as HTMLInputElement;
      const file = target.files?.[0];

      if (file) {
        this.$emit("update:filename", file.name);

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const fileData = e.target?.result as string;
          this.value = fileData;
        };
        fileReader.readAsDataURL(file);
      }
    },
  },
});
</script>
