import axios from "axios";
import type { UserFullyPopulated } from "./models/user";
import type { BusinessUserFullyPopulated } from "./models/business.user";

export const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    AccessControlAllowOrigin: "*",
    UserAuthorization: "Bearer " + localStorage.getItem("userToken"),
    BusinessAuthorization: "Bearer " + localStorage.getItem("businessToken"),
    language: localStorage.getItem("lang") || "nl",
  },
});

export function getHeader(key: string) {
  return instance.defaults.headers[key];
}

export function isLoggedInWithKey(
  key: "UserAuthorization" | "BusinessAuthorization",
) {
  return (
    instance.defaults.headers[key] !== `Bearer null` &&
    instance.defaults.headers[key] !== `Bearer undefined` &&
    instance.defaults.headers[key] !== `Bearer ` &&
    (instance.defaults.headers?.[key] as string)?.length > 15
  );
}

export function setHeader(key: string, value: string) {
  instance.defaults.headers[key] = value;
}

let user: UserFullyPopulated | null;

export async function getUser(
  refresh: boolean = false,
): Promise<UserFullyPopulated | null> {
  // Check if the user is already set or if we want to refresh the user
  if (!user || refresh) {
    // Check if the user is logged in
    if (isLoggedInWithKey("UserAuthorization")) {
      const response = await instance.get("/users/self");
      user = response.data;
    } else {
      return null;
    }
  }
  return user;
}

let businessUser: BusinessUserFullyPopulated | null;
let gettingBussinessUser = false;

export async function getBusinessUser(
  refresh: boolean = false,
  logoutOnFail: boolean = true,
): Promise<BusinessUserFullyPopulated | null> {
  try {
    // Check if the businessUser is already set
    if (!businessUser || refresh) {
      // Check if the businessUser is logged in
      if (
        getHeader("BusinessAuthorization") &&
        getHeader("BusinessAuthorization") !== "Bearer null"
      ) {
        if (gettingBussinessUser) {
          // If already getting user wait 100 seconds and try again to get from cache
          return new Promise((resolve) => {
            setTimeout(() => resolve(getBusinessUser()), 100);
          });
        }

        // Set getting user to true so we don't get the user multiple times
        gettingBussinessUser = true;

        const response = await instance.get("/business-users");
        businessUser = response.data;
        gettingBussinessUser = false;
      } else {
        return null;
      }
    }
    return businessUser;
  } catch (error: any) {
    // If there is an error getting the user, set getting user to false so we can try again
    gettingBussinessUser = false;

    if (error.response?.status === 401 && logoutOnFail) {
      logout();
      window.location.reload();
    }

    return null;
  }
}

export async function logout() {
  // Unset the user and businessUser variables
  user = null;
  businessUser = null;

  // Unset the headers
  setHeader("UserAuthorization", "");
  setHeader("BusinessAuthorization", "");

  // Remove the tokens from local storage
  localStorage.removeItem("userToken");
  localStorage.removeItem("businessToken");

  location.href = "/";
}

export const httpClient = instance;

export default instance;
