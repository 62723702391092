<template>
  <div class="crypto-talent-overlay" data-cy="confirm-modal">
    <div
      class="confirm-modal relative mt-[180px] h-fit w-full max-w-[425px] rounded-[14px] py-5 px-8 flex flex-col justify-center items-center bg-white shadow-md border border-[#101010]"
    >
      <span class="text-center text-2xl text-black font-semibold">
        {{ title }}
      </span>
      <p class="mt-3 text-center text-sm leading-5 text-black">
        {{ message }}
        <slot />
      </p>
      <div class="flex mt-5 md:gap-x-5 gap-x-2">
        <button
          class="w-[156px] h-10 rounded-[14px] flex justify-center items-center text-black text-sm leading-5 bg-white border border-[#190429] shadow-md cursor-pointer transition-colors confirm-modal-cancel-button hover:bg-[#19042933]"
          data-cy="confirm-modal-dismiss-button"
          @click="$emit('dismiss')"
        >
          {{ $t("shared.no") }}
        </button>
        <primary-button
          class="font-semibold w-[156px] h-10 rounded-[14px]"
          data-cy="confirm-modal-confirm-button"
          @click="$emit('confirm')"
        >
          {{ $t("shared.yes") }}
        </primary-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  emits: ["dismiss", "confirm"],
});
</script>
