<script setup lang="ts"></script>

<template>
  <NavBar />

  <main
    class="home-view-container flex flex-col items-center"
    data-cy="home-view"
  >
    <div class="w-full md:hidden block">
      <div v-if="!isLoggedIn" class="home-view-mobile-container">
        <div
          class="home-view-mobile-button text-xs leading-[18px] rounded-[5px] text-center text-[#01fce5] font-semibold relative w-fit px-2 py-1"
          @click="$router.push('/job-offer-create')"
        >
          {{ $t("nav.mobile.addJobOffer") }}
        </div>
        <div
          class="home-view-mobile-button text-xs leading-[18px] rounded-[5px] text-center text-[#01fce5] font-semibold relative w-fit px-2 py-1"
          @click="$router.push('/sign-up')"
        >
          <div>
            {{ $t("nav.mobile.register") }}
          </div>
          <transition name="fade">
            <div v-if="createAccountHoverToggle" class="z-[99999]">
              <div class="home-view-create-account-popup-triangle"></div>
              <div
                class="absolute right-0 bg-white top-[calc(100%+10px)] w-32 min-h-12 py-1 px-1 text-[#000000] flex justify-center items-center rounded-[5px] text-[10px] leading-3 text-center font-normal"
              >
                {{ $t("nav.createAccountHint1") }} <br />
                {{ $t("nav.createAccountHint2") }}
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="$route.query.logout && logoutModalToggle"
        class="fixed top-0 z-[200] opacity-80 w-full px-4 max-w-[600px] mt-8 rounded-[10px]"
        data-cy="mailing-success"
      >
        <div class="home-view-logout-alert-container">
          {{ $t("shared.loggedOff") }}
        </div>
      </div>
    </transition>

    <first-background-ellipse
      v-if="!$root.isMobile"
      class="home-view-background-ellipse"
    />

    <div
      class="home-view-jobs-to-be-found select-none"
      data-cy="home-view-jobs-to-be-found"
      @click="$router.push('/job-offers')"
    >
      {{ $t("pages.home.header") }}
    </div>

    <div class="home-view-jobs-to-be-found-subtitle">
      {{ $t("pages.home.jobsToBeFoundSubtitle") }}
    </div>

    <search-row :vacatures="vacatures" />

    <popular-searches-row :popular-list="popularList" />

    <background-glow v-if="!$root.isMobile" class="home-view-background-glow" />

    <latest-jobs-row :latest-jobs="latestJobs" />

    <second-background-ellipse
      v-if="!$root.isMobile"
      class="home-view-second-background-ellipse"
    />

    <div
      class="home-view-start-search flex flex-col items-center z-10 md:mt-[202px] mt-[157px]"
    >
      <h4>{{ $t("pages.home.startSearch") }}</h4>
      <span>
        {{ $t("pages.home.startSearchText") }}
      </span>
      <primary-button
        class="mt-4 font-semibold"
        data-cy="home-view-upload-resume-button"
        @button-click="() => $router.push('/sign-up')"
      >
        <document-upload-svg class="inline mr-2" />{{ $t("nav.uploadResume") }}
      </primary-button>
    </div>

    <div
      v-if="$root.isMobile"
      class="home-view-mobile-background"
      :style="{
        backgroundPositionY: isLoggedIn ? '-300px' : '-260px',
      }"
    ></div>
  </main>

  <footer-view class="cv-hidden" :quick-links="true" />
  <alert-modal
    v-if="modalToggle"
    :title="$t('error.generic')"
    :message="errorMessage"
    type="info"
    @dismiss="modalToggle = false"
  ></alert-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import httpClient, { getUser, isLoggedInWithKey } from "@/http_client";

import NavBar from "@/components/shared/navbar/NavBar.vue";
import SearchRow from "@/components/home/HomeSearchRow.vue";
import PopularSearchesRow from "@/components/home/HomePopularSearchesRow.vue";
import LatestJobsRow from "@/components/home/HomeLatestJobsRow.vue";
import FirstBackgroundEllipse from "@/components/home/style/FirstBackgroundEllipse.vue";
import SecondBackgroundEllipse from "@/components/home/style/SecondBackgroundEllipse.vue";
import BackgroundGlow from "@/components/shared/style/BackgroundGlow.vue";

import validators from "@/data/validators";

import documentUploadSvg from "@/assets/svg/Svg/All/linear/document-upload.svg?component";

import type { VacatureWithBusinessAndUser } from "@/models/vacature";
import type { UserFullyPopulated } from "@/models/user";
import type { StringOption } from "global";

type VacatureWithBusinessAndUserAndCount = VacatureWithBusinessAndUser & {
  count: number;
};

export default defineComponent({
  name: "HomeView",
  components: {
    NavBar,
    SearchRow,
    PopularSearchesRow,
    LatestJobsRow,
    FirstBackgroundEllipse,
    SecondBackgroundEllipse,
    BackgroundGlow,
    documentUploadSvg,
  },
  data() {
    return {
      keywords: "",
      location: "",
      distance: "10",
      vacatures: [],
      vacaturesLength: 0,
      mailingToggle: false,
      mailingModalToggle: false,
      userMailToggle: false,
      email: "",
      user: null as UserFullyPopulated | null,
      errorMessage: "",
      modalToggle: false,
      popularList: [] as StringOption[],
      recentList: [] as StringOption[],
      latestJobs: [] as VacatureWithBusinessAndUserAndCount[],
      logoutModalToggle: true,
      createAccountHoverToggle: false,
      validators,
    };
  },
  computed: {
    isLoggedIn(): boolean {
      return Boolean(
        isLoggedInWithKey("BusinessAuthorization") ||
          isLoggedInWithKey("UserAuthorization"),
      );
    },
  },
  async mounted() {
    setTimeout(() => {
      this.logoutModalToggle = false;
    }, 1000);

    if (this.$root.isHorizontalMobile)
      setTimeout(() => {
        if (localStorage.getItem("createAccountHoverToggle") === "true") {
          return;
        }

        this.createAccountHoverToggle = true;

        localStorage.setItem("createAccountHoverToggle", "true");

        setTimeout(() => {
          this.createAccountHoverToggle = false;
        }, 60000);
      }, 100);

    const { data: vacatures } = await httpClient.get("/vacatures");
    this.vacaturesLength = vacatures.length;
    this.latestJobs = vacatures
      .reduce(
        (
          acc: (VacatureWithBusinessAndUser & { count: number })[],
          curr: VacatureWithBusinessAndUser,
        ) => {
          const found = acc.find(
            (item: VacatureWithBusinessAndUser) => item.title === curr.title,
          );
          if (found) {
            found.count++;
          } else {
            acc.push({ ...curr, count: 1 });
          }
          return acc;
        },
        [],
      )
      .slice(0, 18);

    const { data: popularSearches } = await httpClient.get("/query-history");
    this.popularList = [
      {
        label: this.$t("shared.allJobOffers"),
        value: "",
      },
      ...popularSearches.map((search: string) => ({
        label: search,
        value: search,
      })),
    ];

    if (window.innerWidth < 768) {
      this.popularList = this.popularList.slice(0, 6);
    }

    this.user = await getUser();
  },
});
</script>

<style lang="scss" scoped>
// eslint-disable vue-scoped-css/no-unused-selector

.home-view-container {
  position: relative;
  padding-bottom: 160px;

  @media screen and (min-width: 431px) and (max-width: 1024px) {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 430px) {
    padding-bottom: 110px;
  }
}

.home-view-logout-alert-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 20px;
  border: 1px solid rgba(181, 0, 253, 0.8);
  background: rgba(131, 0, 203, 0.8);
  text-align: center;
  font-size: 20px;
}

.home-view-create-account-popup-triangle {
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);

  width: 0;
  height: 0;

  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
}

.home-view-background-ellipse {
  position: absolute;
  top: -180px;

  @media screen and (max-width: 430px) {
    top: -120px;
  }

  @media screen and (min-width: 431px) and (max-width: 1024px) {
    top: -40px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1300px) {
    top: -100px;
  }

  @media screen and (min-width: 1300px) {
    top: -160px;
  }

  @media screen and (min-width: 1600px) {
    top: -200px;
  }
}

.home-view-background-glow {
  position: absolute;
  top: 660px;

  @media screen and (max-width: 430px) {
    top: 700px;
    filter: blur(100px);
  }
}

.home-view-second-background-ellipse {
  position: absolute;
  top: 900px;

  @media screen and (max-width: 430px) {
    top: 1300px;
  }

  @media screen and (min-width: 431px) and (max-width: 1024px) {
    top: 1200px;
  }

  @media screen and (min-width: 1600px) {
    top: 1020px;
  }
}

.home-view-jobs-to-be-found {
  @media screen and (max-width: 430px) {
    margin-top: 42px;
    font-size: 24px;
    line-height: 34px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 2rem;
  }

  @media screen and (min-width: 1300px) and (max-width: 1600px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 1600px) {
    font-size: 3rem;
  }

  margin-top: 82px;
  z-index: 10;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.home-view-jobs-to-be-found-subtitle {
  @media screen and (max-width: 430px) {
    margin-top: 24px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  max-width: 800px;
  margin-top: 44px;
  z-index: 10;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
}

// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.home-view-start-search {
  h4 {
    max-width: 56rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 5.25rem;
    line-height: 4.75rem;
    font-weight: 600;

    @media screen and (max-width: 430px) {
      max-width: calc(100% - 20px);
    }

    @media screen and (max-width: 1100px) {
      font-size: 32px;
      line-height: 30px;
    }
  }

  span {
    max-width: 50rem;
    margin: 2rem 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;

    @media screen and (max-width: 430px) {
      margin: 1rem 0;
      max-width: calc(100vw - 50px);
    }

    @media screen and (max-width: 430px) {
      font-size: 11px;
      line-height: 18px;
    }
  }

  button {
    min-width: 22rem;
    padding: 1rem;

    @media screen and (max-width: 430px) {
      min-width: auto;
      width: calc(100% - 40px);
    }
  }
}

.home-view-mobile-background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 430px;
  max-width: 430px;
  height: 100%;
  background-color: #1c0033;
  background: url("@/assets/images/home/home-mobile-background.webp") no-repeat;
  background-size: cover;
  background-position-x: center;
}

// eslint-disable-next-line vue-scoped-css/require-selector-used-inside
.home-view-mobile {
  &-container {
    position: relative;
    margin-top: 22px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    z-index: 20;
  }

  &-button {
    background: rgba(35, 9, 56, 0.7);

    &:hover {
      background: rgba(35, 9, 56, 0.5);
    }
  }
}
</style>

<style lang="scss">
.home-location-input {
  &-container::after {
    border-radius: 14px 0 0 14px !important;
    background: linear-gradient(
      91.67deg,
      rgba(19, 227, 231, 0.9) 0%,
      rgba(167, 19, 251, 0.9) 200%
    );
  }

  & .form-input {
    border-radius: 14px 0 0 14px !important;
  }
}
</style>
