<template>
  <div
    :class="{
      'form-input-container-row': row,
    }"
  >
    <span
      v-if="label"
      class="form-input-label crypto-talent-font-bold"
      :class="{
        'form-input-label-row': row,
      }"
    >
      {{ label }}<span v-if="required" class="text-xs">*</span>
    </span>
    <div
      class="flex"
      :class="{
        'w-full flex-col justify-center items-start': !row && !horizontal,
        'flex-col items-start form-input-row': row,
        'form-radio-horizontal': horizontal,
      }"
    >
      <div
        v-for="(option, index) in options"
        :key="option.value"
        class="form-radio-option"
        :class="{
          'form-radio-option-horizontal': horizontal,
          'form-radio-option-disabled': readonly,
        }"
        :data-cy="`${name}-option-${index + 1}`"
        @click="clickRadio(index)"
      >
        <input
          :ref="`${name}-option-${index + 1}`"
          v-model="value"
          type="radio"
          :name="name"
          :value="option.value"
          :checked="value === option.value"
          :disabled="readonly"
          :data-cy="`${name}-option-${index + 1}-radio`"
          class="absolute top-0 left-0 w-full h-full opacity-0 z-50 cursor-pointer"
        />
        <div v-if="!dot" class="form-radio-option-dot">
          <tick-svg
            v-if="value === option.value || modelValue === option.value"
            class="absolute top-1/2 -left-[3px] -translate-y-1/2"
          />
        </div>
        <div v-else class="form-radio-option-dot">
          <div
            v-if="value === option.value || modelValue === option.value"
            class="form-radio-option-selected-dot absolute top-1/2 left-1/2"
          ></div>
        </div>
        <label :for="name">
          {{ option.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TickSvg from "@/assets/svg/Svg/All/linear/tick.svg?component";

export default defineComponent({
  components: {
    TickSvg,
  },
  props: {
    modelValue: { type: [String, Boolean], required: true },
    name: {
      type: String,
      required: true,
    },
    label: { type: String, required: false, default: "" },
    options: {
      type: Array as () => Array<{ label: string; value: string }>,
      required: true,
    },
    required: { type: Boolean, required: false, default: false },
    row: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    dot: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      value: this.modelValue,
      radioContainerClass: "flex-1 p-3 rounded transition cursor-pointer",
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },
  methods: {
    clickRadio(index: number) {
      if (!this.readonly) {
        this.value = this.options[index].value;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.form-radio-horizontal {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.form-radio-option {
  position: relative;
  display: flex;
  padding: 0.5rem;
  margin: 0 0.2rem;
  justify-content: center;
  gap: 8px;
  align-items: center;
  flex: 1;
  border-radius: 0.75rem;

  transition: all 0.2s ease-in-out;

  & > label {
    @media screen and (max-width: 430px) {
      font-size: 14px;
    }
  }

  &-dot {
    position: relative;
    min-width: 1.25rem;
    min-height: 1.25rem;
    border-radius: 50%;
    border: 1px solid #01fce5;
  }

  &-selected-dot {
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background: #01fce5;
  }

  &-horizontal {
    flex: 0;
    padding: 0.5rem 1rem;
    justify-content: center;

    @media screen and (max-width: 768px) {
      padding: 0.5rem;

      & > label {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  &-disabled {
    cursor: default;

    & > input {
      cursor: default;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
