<template>
  <div
    class="home-view-popular-searches crypto-talent-container md:mt-[140px] mt-[118px]"
  >
    <h2
      class="xl:text-5xl md:text-4xl text-2xl xl:my-4 md:my-3 my-2 font-semibold uppercase"
    >
      {{ $t("pages.home.popular") }}
    </h2>
    <div class="home-view-popular-searches-list">
      <div
        v-for="item in popularList"
        :key="item.value"
        class="home-view-popular-searches-list-item"
        data-cy="home-view-popular-searches-list-item"
        @click="
          $router.push(
            `/job-offers?keywords=${encodeURIComponent(item.value as string)}`,
          )
        "
      >
        <div
          class="home-view-popular-searches-list-item-card crypto-talent-border"
        >
          <search-normal-svg class="mr-2 crypto-talent-svg-medium" />
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import searchNormalSvg from "@/assets/svg/Svg/All/linear/search-normal.svg?component";

export default defineComponent({
  name: "PopularSearchesRow",
  components: {
    searchNormalSvg,
  },
  props: {
    popularList: {
      type: Array as () => {
        label: string;
        value: string;
      }[],
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.home-view-popular-searches {
  width: 100%;
  max-width: 1034px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 10;

  &-list {
    width: 80%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 11px;

    @media screen and (max-width: 430px) {
      margin-top: 16px;
      width: calc(100% - 24px);
      gap: 8px;
    }

    &-item {
      position: relative;

      &-card {
        position: relative;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
        padding-right: 15px;
        border-radius: 12px;
        background: linear-gradient(
          180deg,
          rgba(35, 9, 56, 0.3) 13.22%,
          rgba(12, 0, 21, 0.3) 100%
        );
        cursor: pointer;
        font-size: 0.8rem;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: #13e3e7;
        }

        @media screen and (max-width: 430px) {
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 0.7rem;

          &::after {
            opacity: 0.65;
          }
        }
      }
    }
  }
}
</style>
