<template>
  <div
    class="relative w-[100px] h-14 md:bg-[#3e0663] bg-[#300f60] rounded-[14px] flex justify-between items-center gap-x-1 pl-4 pr-2 cursor-pointer md:hover:opacity-90 transition z-50"
    data-cy="language-selector-select-toggle"
    @click="selectToggle = !selectToggle"
  >
    <dutch-flag-svg v-if="language === 'nl'" class="w-6 h-6" />
    <english-flag-svg v-else class="w-6 h-6" />
    <div class="uppercase font-semibold">
      {{ language }}
    </div>
    <arrow-down-svg class="crypto-talent-svg-medium" />
    <div
      v-if="selectToggle"
      class="absolute top-0 left-0 w-full bg-[#3e0663] rounded-[14px]"
      data-cy="language-selector-select"
    >
      <div
        v-for="lang in languages"
        :key="lang.value"
        class="flex justify-between gap-2 px-4 py-3 w-full hover:bg-[#ffffff1f] transition rounded-[14px] cursor-pointer"
        :data-cy="`language-selector-${lang.value}`"
        @click.stop="changeLanguage(lang.value)"
      >
        <dutch-flag-svg v-if="lang.value === 'nl'" class="w-6 h-6" />
        <english-flag-svg v-else class="w-6 h-6" />
        <div class="uppercase font-semibold">
          {{ lang.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import dutchFlagSvg from "@/assets/svg/flags/dutch.svg?component";
import englishFlagSvg from "@/assets/svg/flags/english.svg?component";
import arrowDownSvg from "@/assets/svg/Svg/All/linear/arrow-down.svg?component";

export default defineComponent({
  name: "LanguageSelector",
  components: {
    dutchFlagSvg,
    englishFlagSvg,
    arrowDownSvg,
  },
  data() {
    return {
      language: localStorage.getItem("lang") || "nl",
      languages: [
        {
          value: "nl",
          label: "🇳🇱 NL",
        },
        {
          value: "en",
          label: "🇬🇧 EN",
        },
      ],
      selectToggle: false,
    };
  },
  watch: {
    language() {
      localStorage.setItem("lang", this.language);
      this.$i18n.locale = this.language;
    },
  },
  methods: {
    changeLanguage(lang: string) {
      this.language = lang;
      this.selectToggle = false;
    },
  },
});
</script>

<style lang="scss">
.language-selector .form-select {
  font-weight: 700;
}
</style>
